<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-12 mt-2">
        <div class="container-fluid">
          <form class="row">
            <div
              class="col-12 col-md-12 mt-2"
              style="border-right: 1px #f4f4f4 solid"
            >
              <div class="container-fluid p-0 mt-4">
                <div class="row">
                  <div class="d-flex flex-column">
                    <span style="text-align: start"> Chọn ảnh</span>
                    <div class="d-flex">
                      <div
                        class="drop-zone m-2"
                        @click="handleClick"
                        @dragover.prevent="handleDragOver"
                        @drop.prevent="handleDrop"
                        :class="{ 'drop-zone--over': isDragging }"
                      >
                        <span
                          class="drop-zone__prompt d-flex justify-content-center align-items-center"
                          >+</span
                        >
                        <input
                          type="file"
                          class="drop-zone__input"
                          @change="handleFileChange"
                          ref="fileInput"
                          style="display: none"
                          multiple
                        />
                      </div>

                      <!-- Hiển thị các ảnh đã chọn -->
                      <div class="selected-images">
                        <div
                          v-for="(thumbnail, index) in thumbnails"
                          :key="index"
                          class="selected-image-container"
                        >
                          <img :src="thumbnail" class="selected-image" />
                          <span class="delete-icon" @click="deleteImage(index)"
                            >×</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 mt-2">
              <textarea class="w-100" style="height: 500px" :value="noiDungUrl">
              </textarea>
            </div>
            <div class="col-12 col-md-12 mt-2">
              <div class="d-flex justify-content-around w-100 p-2 mt-5">
                <button
                  class="w-50"
                  type="button"
                  style="
                    border: 2px #1481da solid;
                    color: white;
                    height: 40px;
                    background: #1481da;
                    border-radius: 15px;
                  "
                  @click="postAnh()"
                >
                 Đẩy ảnh
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { imageUrls } from "@/components/js/urlImg";
import { languages } from "@/components/js/langVpos";
import {
  convertToBase64,
  getMD5,
  KEY_PHONE_ID,
  KEY_SESSION_ID,
  postImage,
} from "@/components/js/VposService";

export default {
  name: "DayAnhLenServer",

  data() {
    return {
      currentLanguage: languages.vi,

      imageUrls: imageUrls,
      address: "",
      latitude: "",
      longitude: "",
      options: {},
      suggestions: [],
      files: [],
      thumbnails: [],
      isDragging: false,
      maxFiles: 10,

      token: "",

      image_key: "",
      imgKeyLogo: "",
      noiDungUrl: "",
      sessionLogin: KEY_SESSION_ID,
      idLogin: KEY_PHONE_ID,
      accOwner: KEY_PHONE_ID,
    };
  },

  methods: {
    handleClick() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const selectedFiles = event.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        if (this.files.length < this.maxFiles) {
          this.files.push(file);
          if (file.type.startsWith("image/")) {
            this.createThumbnail(file);
          } else {
            this.thumbnails.push("");
          }
        } else {
          alert("Bạn chỉ có thể chọn tối đa " + this.maxFiles + " ảnh.");
          break;
        }
      }
    },
    handleDragOver(event) {
      event.preventDefault();
      this.isDragging = true;
    },
    handleDrop(event) {
      event.preventDefault();
      this.isDragging = false;
      const droppedFiles = event.dataTransfer.files;
      for (let i = 0; i < droppedFiles.length; i++) {
        const file = droppedFiles[i];
        if (this.files.length < this.maxFiles) {
          this.files.push(file);
          if (file.type.startsWith("image/")) {
            this.createThumbnail(file);
          } else {
            this.thumbnails.push("");
          }
        } else {
          alert("Bạn chỉ có thể thả tối đa " + this.maxFiles + " ảnh.");
          break;
        }
      }
    },
    createThumbnail(file) {
      const thumbnailURL = URL.createObjectURL(file);
      this.thumbnails.push(thumbnailURL);
    },
    deleteImage(index) {
      this.thumbnails.splice(index, 1);
      this.files.splice(index, 1);
    },

    async postAnh() {
      try {
        const imgURLs = [];
        for (const thumbnail of this.thumbnails) {
          const value = await convertToBase64(thumbnail);
          const idCheck = await getMD5(convertToBase64(thumbnail));

          const res = await postImage(value, idCheck);
          console.log("res "+ res);
          imgURLs.push(res.result); 
        }
        this.concatenatedURLs = imgURLs.join(";");
        console.log("this.concatenatedURLs " + this.concatenatedURLs);
        this.noiDungUrl = imgURLs;
        console.log("aaaa " + this.concatenatedURLs);
      } catch (e) {
        console.log("Lỗi đẩy ảnh",e);
        alert("Lỗi đẩy ảnh");
      }
    },
  },
};
</script>
  <style>
.drop-zone {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 30px;
  cursor: pointer;
  color: #088dc2;
  border: 1px solid #088dc2;
  border-radius: 10px;
}

.drop-zone--over {
  border-style: solid;
}

.drop-zone__input {
  display: none;
}

.drop-zone__thumb {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #cccccc;
  background-size: cover;
  background-position: center;
  position: relative;
}

.drop-zone__thumb::after {
  content: attr(data-label);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  text-align: center;
}
.selected-images {
  display: flex;
  flex-wrap: wrap;
  background-size: cover;
  background-position: center;
}

.selected-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin: 5px;
}
.selected-image-container {
  position: relative;
  display: inline-block;
}

.delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 25px;
  width: 25px;
  background-color: rgb(201 201 201 / 70%);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}
</style>