<template>
  <header>
    <label for="toggle-1" class="toggle-menu" @click="toggleMenu"
      ><ul>
        <li></li>
        <li></li>
        <li></li></ul
    ></label>
    <!-- <strong @click="toggleMenu"> &#9776;	</strong> -->
    <nav
      class="nav_center"
      style="padding: 0"
      :style="{ display: isMenuVisible ? 'block' : 'none' }"
    >
      <ul style="list-style: none; color: white">
        <li>
          <a id="urlAITaiLopHoc">
            {{ currentLanguage.header_text_theDaNang_AITaiLopHoc }}
          </a>
        </li>
        <li>
          <a id="urlThiDiem">
            {{ currentLanguage.header_text_theDaNang_CTTaiCho }}
          </a>
        </li>

        <li>
          <a id="urlBai1">
            {{ currentLanguage.header_text_theDaNang_mamNonTieuHoc }}
          </a>
        </li>

        <li>
          <a id="urlBai2">
            {{ currentLanguage.header_text_theDaNang_thcsThpt }}
          </a>
        </li>

        <li>
          <a id="urlBai3"> {{ currentLanguage.header_text_theDaNang_Thpt }}</a>
        </li>

        <li>
          <a id="urlLienCap">Liên cấp</a>
        </li>
        <li>
          <a id="urlTaiTro">
            {{ currentLanguage.header_text_theDaNang_ctTaiTro }}</a
          >
        </li>
        
      
        <li>
          <a id="urlLienHe">{{ currentLanguage.header_text_lienHe }}</a>
        </li>
        <li
      
      >
        <a id="urlPerplexity">Thư viện</a>
      </li>
      <li >
        <a id="urlUngDungAI">Ứng dụng AI phổ biến</a>
      </li>
      </ul>
    </nav>
    <div class="menu_right" style="display: flex">
      <a @click="changeLanguage('vi')" class="lang" style="padding: 5px"
        ><img src="img/img-item-vimass-2022/co/vi-VN.gif" />
      </a>
      <a @click="changeLanguage('en')" class="lang" style="padding: 5px"
        ><img style="width: 25px" src="img/img-item-vimass-2022/co/us.png" />
      </a>
      <a @click="changeLanguage('cn')" class="lang d-none" style="padding: 5px"
        ><img style="width: 25px" src="img/img-item-vimass-2022/co/cn.jpg" />
      </a>
      <a @click="changeLanguage('kr')" class="lang d-none" style="padding: 5px"
        ><img style="width: 25px" src="img/img-item-vimass-2022/co/kr.jpg" />
      </a>

      <span
        class="clickShow"
        style="color: #ffffff; cursor: pointer; margin: auto; padding: 5px"
        ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
      ></span>
      <div class="showCo" style="display: none; margin: auto">
        <a onclick="changeLang('us')" class="lang"
          ><img src="img/img-item-vimass-2022/co/indo.jpg" />
        </a>
        <a onclick="changeLang('us')" class="lang"
          ><img src="img/img-item-vimass-2022/co/tl.png" />
        </a>
        <a onclick="changeLang('us')" class="lang"
          ><img src="img/img-item-vimass-2022/co/thailan.png" />
        </a>
        <a onclick="changeLang('us')" class="lang"
          ><img src="img/img-item-vimass-2022/co/hu.jpg" />
        </a>
      </div>
      <a id="urlHome" href="/" style="margin: 0 10px"
        ><img
          style="width: 35px; height: 35px"
          src="img/home-icon-white-8.jpg"
        />
      </a>
    </div>
  </header>
  <div class="wrapper row3">
    <main class="hoc container-fluid clear bv">
      <div style="min-height: 500px" class="padding-top15">
        <div class="title-bai-viet" v-html="titleHeThong"></div>
        <div v-html="contentHeThong"></div>
      </div>
    </main>
  </div>
  <FooterView></FooterView>
</template>

<script>
import { languages } from "@/components/languages";
import axios from "axios";
import { apiChiTietBaiVietVer2 } from "@/components/listService";
import FooterView from "@/views/Vimass/Footer/FooterView.vue";
export default {
  components: {
    FooterView,
  },
  data() {
    return {
      currentLanguage: languages.vi,
      isMenuVisible: false,

      lang: "",
      base64: "",
      base64Title: "",
      //title
      titleHeThong: "",
      //content
      contentHeThong: "",
      hasMounted: false,
      idBaiViet: "",
    };
  },

  mounted() {
    if (window.innerWidth <= 768) {
      this.isMenuVisible = false;
    } else {
      this.isMenuVisible = true;
    }

    let url = window.location.href;
    let pathArray = url.split("/");
    let lang1 = pathArray[pathArray.length - 3];
    localStorage.setItem("languages", lang1);
    const lang = localStorage.getItem("languages");
    console.log(lang1 + "   a");
    if (this.lang == "vi") {
      document.title = "Vimass.vn - Bài viết";
    } else if (this.lang == "en") {
      document.title = "Vimass.vn - Article details";
    } else if (this.lang == "cn") {
      document.title = "Vimass.vn - 帖子";
    } else if (this.lang == "kr") {
      document.title = "Vimass.vn - 게시물";
    }
    this.lang = lang;
    // document.getElementById("urlHome").setAttribute("href", this.lang);
    document
      .getElementById("urlAITaiLopHoc")
      .setAttribute("href", this.lang + "/giaoduc#phocaptrituenhantao/");
    document
      .getElementById("urlThiDiem")
      .setAttribute("href", this.lang + "/giaoduc#chuongtrinhtaitro/");
    document
      .getElementById("urlBai2")
      .setAttribute("href", this.lang + "/giaoduc#trunghoccoso/");
    document
      .getElementById("urlBai3")
      .setAttribute("href", this.lang + "/giaoduc#trunghocphothong/");
    // document.getElementById("urlTaiTro").setAttribute("href", this.lang + "/giaoduc#truongduoctaitro/");
    document
      .getElementById("urlBai1")
      .setAttribute("href", this.lang + "/giaoduc#tieuhoc/");
    document
      .getElementById("urlLienCap")
      .setAttribute("href", this.lang + "/giaoduc#liencap/");
    document
      .getElementById("urlLienHe")
      .setAttribute("href", this.lang + "/giaoduc#lienhe/");
    document
      .getElementById("urlTaiTro")
      .setAttribute("href", this.lang + "/giaoduc#thoathuantaitro/");
      document
        .getElementById("urlUngDungAI")
        .setAttribute("href", this.lang + "/giaoduc#ungdungaiphobien/perplexity"); 
        document
        .getElementById("urlPerplexity")
        .setAttribute("href", this.lang + "/giaoduc#thuvientruong/");
    if (!this.hasMounted) {
      this.hasMounted = true;
      this.idBaiViet = this.$route.params.idTitleVi;
      console.log("this.$route.params.id; ", this.$route.params.idTitleVi);

      if (this.lang) {
        // Kiểm tra giá trị của this.lang trước khi sử dụng
        this.$router.push({
          path: "/" + this.lang + "/giao-duc/" + this.idBaiViet,
        });
      }
    }

    // if (this.$refs.manHinhChinh) {
    //   const imgElement = this.$refs.manHinhChinh;
    //   imgElement.addEventListener("click", this.handleClick);
    //   console.log("Ref tồn tại");
    // } else {
    //   console.log("Ref không tồn tại");
    // }
    if (this.lang && languages[this.lang]) {
      this.currentLanguage = languages[this.lang];
    }

    this.performPostRequest(this.idBaiViet);
  },

  methods: {
    handleClick() {
      // Xử lý sự kiện click ở đây
      console.log("Đã click vào màn hình chính");
    },

    toggleMenu() {
      this.isMenuVisible = true;
    },
    changeLanguage(lang) {
      // this.currentLanguage = languages[lang];
      // localStorage.setItem("languages", lang);
      // window.location.reload();
      // const currentPath = this.$route.fullPath;
      const idBaiViet = this.idBaiViet; // Đảm bảo biến idBaiViet đã được định nghĩa ở nơi khác

      // Cập nhật URL mới với ngôn ngữ và id bài viết
      const newPath = "/" + lang + "/giao-duc/" + idBaiViet;

      // Reload trang với URL mới
      window.location.href = newPath;
    },

    async performPostRequest(id) {
      this.lang = localStorage.getItem("languages");
      try {
        // Thực hiện POST request
        const response = await axios.post(apiChiTietBaiVietVer2, {
          idTitleVi: id,
          langId: "0",
        });

        if (this.lang === "vi") {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        } else if (this.lang === "en") {
          this.base64 = response.data.result.content_en;
          this.base64Title = response.data.result.title_en;
        } else if (this.lang === "cn") {
          this.base64 = response.data.result.content_cn;
          this.base64Title = response.data.result.title_cn;
        } else if (this.lang === "kr") {
          this.base64 = response.data.result.content_kr;
          this.base64Title = response.data.result.title_kr;
        } else {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        }
        var title = atob(this.base64Title);
        var decodedString = atob(this.base64);

        this.titleHeThong = decodeURIComponent(escape(title));
        this.contentHeThong = decodeURIComponent(escape(decodedString));
      } catch (error) {
        console.error("Error:", error);
      }
    },
  },
};
</script>
<style scoped>
.f-s--title {
  font-size: 16.4px;
}
</style>
