<template>
  <header class="d-flex justify-content-between align-items-center">
    <label for="toggle-1" class="toggle-menu" @click="toggleMenu"
      ><ul>
        <li></li>
        <li></li>
        <li></li></ul
    ></label>
    <!-- <strong @click="toggleMenu"> &#9776;	</strong> -->
    <nav
      class="nav_center"
      style="padding: 0"
      :style="{ display: isMenuVisible ? 'block' : 'none' }"
    >
      <ul style="list-style: none">
        <li
          :class="{ topmenuactive: activeTab === 'phocapthesinhtrachoc' }"
          @click="changeTab('phocapthesinhtrachoc')"
        >
          <a
            id="urlPhoCapTheSinhTracHoc"
            href="thesinhtrachoc#phocapthesinhtrachoc/"
            >{{ currentLanguage.header_text_phoCapThe }}</a
          >
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'vcard' }"
          @click="changeTab('vcard')"
        >
          <a id="urlVcard" href="thesinhtrachoc#vcard/">{{
            currentLanguage.header_text_viDienTu
          }}</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'theChip' }"
          @click="changeTab('theChip')"
        >
          <a id="theChip" href="thesinhtrachoc#thechip/">{{
            currentLanguage.header_text_theChip
          }}</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'DiemThanhToan' }"
          @click="changeTab('DiemThanhToan')"
        >
          <a id="urlBai1" href="thesinhtrachoc#diemthanhtoan/">{{
            currentLanguage.footer_text_diemThanhToan
          }}</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'DiemDinhDanh' }"
          @click="changeTab('DiemDinhDanh')"
        >
          <a id="urlBai2" href="thesinhtrachoc#diemdinhdanh/">{{
            currentLanguage.footer_text_diemRaVao
          }}</a>
        </li>

        <!-- <li
          :class="{ topmenuactive: activeTab === 'lienhe' }"
          @click="changeTab('lienhe')"
        >
          <a id="urlBai3" href="thesinhtrachoc#lienhe/">{{
            currentLanguage.header_text_lienHe
          }}</a>
        </li> -->
      </ul>
    </nav>
    <div class="menu_right" style="display: flex">
      <div class="menu_co_right" style="display: flex">
        <a @click="changeLanguage('vi')" class="lang" style="padding: 5px"
          ><img src="img/img-item-vimass-2022/co/vi-VN.gif" />
        </a>
        <a @click="changeLanguage('en')" class="lang" style="padding: 5px"
          ><img style="width: 25px" src="img/img-item-vimass-2022/co/us.png" />
        </a>
        <a @click="changeLanguage('cn')" class="lang d-none" style="padding: 5px"
          ><img style="width: 25px" src="img/img-item-vimass-2022/co/cn.jpg" />
        </a>
        <a @click="changeLanguage('kr')" class="lang d-none" style="padding: 5px"
          ><img style="width: 25px" src="img/img-item-vimass-2022/co/kr.jpg" />
        </a>
        <a @click="changeLanguage('ja')" class="lang d-none" style="padding: 5px"
          ><img style="width: 25px" src="img/img-item-vimass-2022/co/ja.jpg" />
        </a>

        <span
          class="clickShow"
          style="color: #303038; cursor: pointer; margin: auto"
          ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
        ></span>
      </div>
      <a id="urlHome" href="/" style="margin: 0 10px"
        ><img
          style="width: 35px; height: 35px"
          src="img/home-icon-white-8.jpg"
        />
      </a>
    </div>
  </header>
  <div class="wrapper row3">
    <main class="hoc container-fluid clear bv">
         <!-- pho cap sinh trac hoc -->
      <div
        id="phocapthesinhtrachoc"
        v-show="showPhoCapSinhTracHoc"
        style="min-height: 500px; background-image: none !important"
        class="padding-top15"
      >
        <div class="title-bai-viet" v-html="titlePhoCapSinhTracHoc"></div>
        <div v-html="contentPhoCapSinhTracHoc"></div>
        <!-- <img-app-vcard></img-app-vcard> -->
      </div>
      <!-- vcard -->
      <div
        id="vcard"
        v-show="showVcard"
        style="min-height: 500px; background-image: none !important"
        class="padding-top15"
      >
        <div class="title-bai-viet" v-html="titleVCard"></div>
        <div v-html="contentVCard"></div>
        <!-- <img-app-vcard></img-app-vcard> -->
      </div>
      <!-- gioi thieu -->
      <div
        id="gioiThieu"
        v-show="showGioiThieu"
        style="min-height: 500px"
        class="padding-top15"
      >
        <div class="title-bai-viet" v-html="titleGioiThieu"></div>
        <div v-html="contentGioiThieu"></div>
      </div>
      <!-- DiemThanhToan-->
      <div
        id="theChip"
        v-show="showTheChip"
        style="min-height: 500px; background-image: none !important"
        class="padding-top15"
      >
        <div class="title-bai-viet" v-html="titleTheChip"></div>
        <div v-html="contentTheChip"></div>
      </div>
      <div
        id="DiemThanhToan"
        v-show="showDiemThanhToan"
        style="min-height: 500px; background-image: none !important"
        class="padding-top15"
      >
        <div class="title-bai-viet" v-html="titleDiemThanhToan"></div>
        <div v-html="contentDiemThanhToan"></div>
      </div>
      <!-- DiemDinhDanh -->
      <div
        id="DiemDinhDanh"
        v-show="showDiemDinhDanh"
        style="min-height: 500px; background-image: none !important"
        class="padding-top15"
      >
        <div class="title-bai-viet" v-html="titleDiemDinhDanh"></div>
        <div v-html="contentDiemDinhDanh"></div>
      </div>

      <!-- LIEN HE -->
      <div
        id="lienhe"
        v-show="showLienHe"
        style="min-height: 500px; background-image: none !important"
      >
        <div class="title-bai-viet" v-html="titleLienHe"></div>
        <div v-html="contentLienHe"></div>
      </div>
    </main>
  </div>
  <FooterView></FooterView>
</template>
  <script>
import FooterView from "@/views/Vimass/Footer/FooterView.vue";
import { languages } from "@/components/languages";
import { apiChiTietBaiViet } from "@/components/listService";
import axios from "axios";
import ImgAppVcard from "../imgApp/imgAppVcard.vue";

export default {
  components: {
    FooterView,
    // eslint-disable-next-line vue/no-unused-components
    ImgAppVcard,
  },
  data() {
    return {
      activeTab: "phocapthesinhtrachoc",
      isMenuVisible: false,

      currentLanguage: languages.vi,
      lang: "",
      base64: "",
      base64Title: "",

      //show
      showPhoCapSinhTracHoc: true,
      showDiemThanhToan: false,
      showTheChip: false,
      showDiemDinhDanh: false,
      showLienHe: false,
      showVcard: false,
      //active tab
      isPhoCapSinhTracHoc: true,
      isActiveDiemThanhToan: false,
      isActiveTheChip: false,
      isActiveDiemDinhDanh: false,
      isActiveLienHe: false,
      isActiveVcard: false,
      //title
      titlePhoCapSinhTracHoc: "",
      titleDiemThanhToan: "",
      titleTheChip: "",
      titleDiemDinhDanh: "",
      titleLienHe: "",
      titleVCard: "",
      //content
      contentPhoCapSinhTracHoc: "",
      contentTheChip: "",
      contentDiemThanhToan: "",
      contentDiemDinhDanh: "",
      contentLienHe: "",
      contentVCard: "",
      hasMounted: false,
    };
  },
  mounted() {
    if (window.innerWidth <= 768) {
      this.isMenuVisible = false;
    } else {
      this.isMenuVisible = true;
    }
    if (!this.hasMounted) {
      this.hasMounted = true; // Set the flag to true to indicate that mounted has been executed
      this.lang = localStorage.getItem("languages");
      if (this.lang == "vi") {
        document.title = "Vimass.vn - Điểm thanh toán & định danh";
      } else if (this.lang == "en") {
        document.title = "Vimass.vn - Payment pointd & Identification points";
      } else if (this.lang == "cn") {
        document.title = "Vimass.vn - 付款点及识别点";
      } else if (this.lang == "kr") {
        document.title = "Vimass.vn - 결제 및 식별 포인트";
      }
      this.performPostRequest("1741163491160s34XH", "phocapthesinhtrachoc", this.lang);

      // window.location.href = this.lang + "/thesinhtrachoc#vwallet/";
      document.getElementById("urlHome").setAttribute("href", this.lang);
      document
        .getElementById("urlPhoCapTheSinhTracHoc")
        .setAttribute("href", this.lang + "/thesinhtrachoc#phocapthesinhtrachoc/");
      document
        .getElementById("urlBai1")
        .setAttribute(
          "href",
          this.lang + "/thesinhtrachoc#diemthanhtoan/"
        );
      document
        .getElementById("urlVcard")
        .setAttribute("href", this.lang + "/thesinhtrachoc#vwallet/");
      document
        .getElementById("urlBai2")
        .setAttribute(
          "href",
          this.lang + "/thesinhtrachoc#diemdinhdanh/"
        );
      // document
      //   .getElementById("urlBai3")
      //   .setAttribute("href", this.lang + "/thesinhtrachoc#lienhe/");
      document
        .getElementById("theChip")
        .setAttribute("href", this.lang + "/thesinhtrachoc#thechip/");

      const hash = window.location.hash;
      if (hash && hash.includes("#phocapthesinhtrachoc")) {
        this.changeTab("phocapthesinhtrachoc");
      } else if (hash && hash.includes("#vwallet")) {
        this.changeTab("vcard");
      } else if (hash && hash.includes("#thechip")) {
        this.changeTab("theChip");
      } else if (hash && hash.includes("#diemthanhtoan")) {
        this.changeTab("DiemThanhToan");
      } else if (hash && hash.includes("#diemdinhdanh")) {
        this.changeTab("DiemDinhDanh");
      } else if (hash && hash.includes("#lienhe")) {
        this.changeTab("lienhe");
      } else {
        this.changeTab("phocapthesinhtrachoc");
      }
    }
  },
  created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
  methods: {
    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
      window.location.reload();
    },

    async performPostRequest(id, section) {
      this.lang = localStorage.getItem("languages");

      try {
        // Thực hiện POST request
        const response = await axios.post(apiChiTietBaiViet, {
          id: id,
          langId: "0",
        });
        if (this.lang === "vi") {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        } else if (this.lang === "en") {
          this.base64 = response.data.result.content_en;
          this.base64Title = response.data.result.title_en;
        } else if (this.lang === "cn") {
          this.base64 = response.data.result.content_cn;
          this.base64Title = response.data.result.title_cn;
        } else if (this.lang === "kr") {
          this.base64 = response.data.result.content_kr;
          this.base64Title = response.data.result.title_kr;
        } else {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        }

        var title = atob(this.base64Title);
        var decodedString = atob(this.base64);

        // Store content separately for each section
        if (section === "phocapthesinhtrachoc") {
          this.titlePhoCapSinhTracHoc = decodeURIComponent(escape(title));
          this.contentPhoCapSinhTracHoc = decodeURIComponent(
            escape(decodedString)
          );
        } else if (section === "vcard") {
          this.titleVCard = decodeURIComponent(escape(title));
          this.contentVCard = decodeURIComponent(escape(decodedString));
        } else if (section === "DiemDinhDanh") {
          this.titleDiemDinhDanh = decodeURIComponent(escape(title));
          this.contentDiemDinhDanh = decodeURIComponent(escape(decodedString));
        } else if (section === "lienhe") {
          this.titleLienHe = decodeURIComponent(escape(title));
          this.contentLienHe = decodeURIComponent(escape(decodedString));
        } else if (section === "DiemThanhToan") {
          this.titleDiemThanhToan = decodeURIComponent(escape(title));
          this.contentDiemThanhToan = decodeURIComponent(escape(decodedString));
        } else if (section === "theChip") {
          this.titleTheChip = decodeURIComponent(escape(title));
          this.contentTheChip = decodeURIComponent(escape(decodedString));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    toggleMenu() {
      this.isMenuVisible = true;
    },
    changeTab(tab) {
      if (window.innerWidth <= 768) {
        this.isMenuVisible = false;
      }
      this.lang = localStorage.getItem("languages");
      if (tab === "phocapthesinhtrachoc") {
        this.isPhoCapSinhTracHoc = true;
        this.isActiveTheChip = false;
        this.isActiveDiemThanhToan = false;
        this.isActiveDiemDinhDanh = false;
        this.isActiveLienHe = false;
        this.isActiveVcard = false;
        this.performPostRequest(
          "1741163491160s34XH",
          "phocapthesinhtrachoc",
          this.lang
        );
      }
      if (tab === "theChip") {
        this.isPhoCapSinhTracHoc = false;
        this.isActiveTheChip = true;
        this.isActiveDiemThanhToan = false;
        this.isActiveDiemDinhDanh = false;
        this.isActiveLienHe = false;
        this.isActiveVcard = false;
        this.performPostRequest("1546059256581d0h0r", "theChip", this.lang);
      }

      if (tab === "vcard") {
        this.isPhoCapSinhTracHoc = false;
        this.isActiveTheChip = false;
        this.isActiveDiemThanhToan = false;
        this.isActiveDiemDinhDanh = false;
        this.isActiveLienHe = false;
        this.isActiveVcard = true;
        this.performPostRequest("169103571793214rn5", "vcard", this.lang);
      } else if (tab === "DiemThanhToan") {
        this.isActiveTheChip = false;
        this.isPhoCapSinhTracHoc = false;
        this.isActiveDiemThanhToan = true;
        this.isActiveDiemDinhDanh = false;
        this.isActiveLienHe = false;
        this.isActiveVcard = false;
        this.performPostRequest(
          "1741085821697JKSk1",
          "DiemThanhToan",
          this.lang
        );
      } else if (tab === "DiemDinhDanh") {
        this.isActiveTheChip = false;
        this.isPhoCapSinhTracHoc = false;
        this.isActiveDiemThanhToan = false;
        this.isActiveDiemDinhDanh = true;
        this.isActiveLienHe = false;
        this.isActiveVcard = false;
        this.performPostRequest(
          "1684483847412mqp7d",
          "DiemDinhDanh",
          this.lang
        );
      } else if (tab === "lienhe") {
        this.isActiveTheChip = false;
        this.isPhoCapSinhTracHoc = false;
        this.isActiveDiemThanhToan = false;
        this.isActiveDiemDinhDanh = false;
        this.isActiveLienHe = true;
        this.isActiveVcard = false;
        this.performPostRequest("1690272053288yg3c7", "lienhe", this.lang);
      }

      this.activeTab = tab; // Cập nhật giá trị activeTab
      this.showPhoCapSinhTracHoc = tab === "phocapthesinhtrachoc";
      this.showVcard = tab === "vcard";
      this.showDiemThanhToan = tab === "DiemThanhToan";
      this.showDiemDinhDanh = tab === "DiemDinhDanh";
      this.showLienHe = tab == "lienhe";
      this.showTheChip = tab == "theChip";
    },
  },
};
</script>
  
  