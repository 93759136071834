<template>
    <nav class="nav_center2" style="padding: 0; background: cadetblue">
  <ul class="" style="list-style: none">
    <li
          :class="{ topmenuactive: activeTab === 'Perplexity' }"
          @click="changeTab('Perplexity')"
        >
          <a id="urlPerplexity" href="vi/giaoduc#ungdungaiphobien/perplexity" style="color: white;">Perplexity</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'Gemini' }"
          @click="changeTab('Gemini')"
        >
          <a id="urlGemini" href="vi/giaoduc#ungdungaiphobien/gemini" style="color: white;">Gemini</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'ChatGPT' }"
          @click="changeTab('ChatGPT')"
        >
          <a id="urlChatGPT" href="vi/giaoduc#ungdungaiphobien/chatgpt" style="color: white;">ChatGPT</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'DeepSeek' }"
          @click="changeTab('DeepSeek')"
        >
          <a id="urlDeepSeek" href="vi/giaoduc#ungdungaiphobien/deepseek" style="color: white;">DeepSeek</a>
        </li>
       
        <li
          :class="{ topmenuactive: activeTab === 'Grok' }"
          @click="changeTab('Grok')"
        >
          <a id="urlGrok" href="vi/giaoduc#ungdungaiphobien/grok" style="color: white;">Grok</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'Copilot' }"
          @click="changeTab('Copilot')"
        >
          <a id="urlCopilot" href="vi/giaoduc#ungdungaiphobien/copilot" style="color: white;">Copilot</a>
        </li>
       
        <li
          :class="{ topmenuactive: activeTab === 'Meta' }"
          @click="changeTab('Meta')" style="display: none;"
        >
          <a id="urlMeta" href="vi/giaoduc#ungdungaiphobien/metaai" style="color: white;">Meta AI</a>
        </li>
        <li 
          :class="{ topmenuactive: activeTab === 'Manus' }" style="display: none;"
          @click="changeTab('Manus')"
        >
          <a id="urlManus" href="vi/giaoduc#ungdungaiphobien/manus"  style="color: white;">Manus</a>
        </li>
      </ul>
    </nav>
    <div class="wrapper row3">
    <main class="hoc container-fluid clear bv">
      <div class="wrapper row3">
        <main class="hoc container-fluid clear bv">
          <!-- Perplexity -->
          <div v-show="showDeepSeek" class="padding-top15">
        <div class="title-bai-viet" v-html="titleHtmlDeepSeek"></div>
        <div v-html="contentHtmlDeepSeek"></div>
      </div>
      <div v-show="showChatGPT" class="padding-top15">
        <div class="title-bai-viet" v-html="titleHtmlChatGPT"></div>
        <div v-html="contentHtmlChatGPT"></div>
      </div>
      <div v-show="showGemini" class="padding-top15">
        <div class="title-bai-viet" v-html="titleHtmlGemini"></div>
        <div v-html="contentHtmlGemini"></div>
      </div>
      <div v-show="showCopilot" class="padding-top15">
        <div class="title-bai-viet" v-html="titleHtmlCopilot"></div>
        <div v-html="contentHtmlCopilot"></div>
      </div>
      <div v-show="showGrok" class="padding-top15">
        <div class="title-bai-viet" v-html="titleHtmlGrok"></div>
        <div v-html="contentHtmlGrok"></div>
      </div>
      <div v-show="showMeta" class="padding-top15">
        <div class="title-bai-viet" v-html="titleHtmlMeta"></div>
        <div v-html="contentHtmlMeta"></div>
      </div>
      <div v-show="showManus" class="padding-top15">
        <div class="title-bai-viet" v-html="titleHtmlManus"></div>
        <div v-html="contentHtmlManus"></div>
      </div>
      <div v-show="showPerplexity" class="padding-top15">
        <div class="title-bai-viet" v-html="titleHtmlPerplexity"></div>
        <div v-html="contentHtmlPerplexity"></div>
      </div>
        </main>
      </div>
    </main>
  </div>

</template>
<script>
import { languages } from "@/components/languages";
import { apiChiTietBaiViet } from "@/components/listService";
import axios from "axios";


export default {
  components: {

  },
  data() {
    return {
      activeTab: 'Perplexity',

      currentLanguage: languages.vi,
      lang: "",
      base64: "",
      base64Title: "",

      showDeepSeek: false,
      showChatGPT: false,
      showGemini: false,
      showCopilot: false,
      showGrok: false,
      showMeta: false,
      showManus: false,
      showPerplexity: false,
      showUngDungAI: false,

      titleHtmlAITaiLopHoc: "",
      contentHtmlAITaiLopHoc: "",
      titleHtml: "",
      contentHtml: "",
      titleHtmlTruongTaiTro: "",
      contentHtmlTruongTaiTro: "",
      titleHtmlTH: "",
      contentHtmlTH: "",
      titleHtmlTHCS: "",
      contentHtmlTHCS: "",
      titleHtmlTHPT: "",
      contentHtmlTHPT: "",
      titleHtmlLienHe: "",
      contentHtmlLienHe: "",
      titleHtmlLienCap: "",
      contentHtmlLienCap: "",

      titleHtmlPerplexity:"",
      titleHtmlDeepSeek:"",
      titleHtmlChatGPT:"",
      titleHtmlGemini:"",
      titleHtmlCopilot:"",
      titleHtmlGrok:"",
      titleHtmlMeta:"",
      titleHtmlManus:"",

      contentHtmlPerplexity:"",
      contentHtmlDeepSeek:"",
      contentHtmlChatGPT:"",
      contentHtmlCopilot:"",
      contentHtmlGemini:"",
      contentHtmlGrok:"",
      contentHtmlMeta:"",
      contentHtmlManus:"",
    };
  },
  mounted() {
    if (window.innerWidth <= 768) {
    this.isMenuVisible = false;
  } else {
    this.isMenuVisible = true;
  }
  if (!this.hasMounted) {
    this.hasMounted = true;
    this.lang = localStorage.getItem("languages");  
    // this.performPostRequest("1695975526923tLcPE", "Perplexity", this.lang);
    document.getElementById("urlPerplexity").setAttribute("href", this.lang+ "/giaoduc#ungdungaiphobien/perplexity");
    document.getElementById("urlGemini").setAttribute("href", this.lang+ "/giaoduc#ungdungaiphobien/gemini");
    document.getElementById("urlChatGPT").setAttribute("href", this.lang+ "/giaoduc#ungdungaiphobien/chatgpt");
    document.getElementById("urlDeepSeek").setAttribute("href", this.lang+ "/giaoduc#ungdungaiphobien/deepseek");
    document.getElementById("urlMeta").setAttribute("href", this.lang + "/giaoduc#ungdungaiphobien/metaai/");
    document.getElementById("urlManus").setAttribute("href", this.lang + "/giaoduc#ungdungaiphobien/manus/");
    document.getElementById("urlGrok").setAttribute("href", this.lang + "/giaoduc#ungdungaiphobien/grok/");
    document.getElementById("urlCopilot").setAttribute("href", this.lang + "/giaoduc#ungdungaiphobien/copilot/");
 
 
 
    const fragmentTab = window.location.hash.substring(1);
    console.log("fragmentTab "+ fragmentTab);
    
    if (fragmentTab === "ungdungaiphobien/perplexity") {
      this.changeTab("Perplexity");
    } else if (fragmentTab === "ungdungaiphobien/gemini") {
      this.changeTab("Gemini");
    } else if (fragmentTab === "ungdungaiphobien/chatgpt") {
      this.changeTab("ChatGPT");
    } else if (fragmentTab === "ungdungaiphobien/deepseek") {
      this.changeTab("DeepSeek");
    }else if (fragmentTab ==="ungdungaiphobien/copilot") {
        this.changeTab("Copilot");
      }else if (fragmentTab ==="ungdungaiphobien/grok") {
        this.changeTab("Grok");
      }else if (fragmentTab ==="ungdungaiphobien/metaai") {
        this.changeTab("Meta");
      }else if (fragmentTab ==="ungdungaiphobien/manus") {
        this.changeTab("Manus");
      }  else {
      // Nếu không phù hợp với bất kỳ tab nào, mặc định hiển thị tab gioiThieu
      this.changeTab("Perplexity");
    }
}
  },
  created() {
  const savedLanguage = localStorage.getItem("languages");
  if (savedLanguage && languages[savedLanguage]) {
    this.currentLanguage = languages[savedLanguage];
  }
},
  methods: {
    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
    window.location.reload();
    },

    async performPostRequest(id, section) {
      this.lang = localStorage.getItem("languages");

      try {
        // Thực hiện POST request
        const response = await axios.post(apiChiTietBaiViet, {
          id: id,
          langId: "0",
        });
        if (this.lang === "vi") {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        } else if (this.lang === "en") {
          this.base64 = response.data.result.content_en;
          this.base64Title = response.data.result.title_en;
        }else if (this.lang === "cn") {
        this.base64 = response.data.result.content_cn;
        this.base64Title = response.data.result.title_cn;
      }else if (this.lang === "kr") {
        this.base64 = response.data.result.content_kr;
        this.base64Title = response.data.result.title_kr;
      }

        var title = atob(this.base64Title);
        var decodedString = atob(this.base64);

        // Store separately for each section
     if (section === "DeepSeek") {
          this.titleHtmlDeepSeek = decodeURIComponent(escape(title));
          this.contentHtmlDeepSeek = decodeURIComponent(escape(decodedString));
        } else if (section === "ChatGPT") {
          this.titleHtmlChatGPT = decodeURIComponent(escape(title));
          this.contentHtmlChatGPT= decodeURIComponent(escape(decodedString));
        } else if (section === "Gemini") {
          this.titleHtmlGemini = decodeURIComponent(escape(title));
          this.contentHtmlGemini = decodeURIComponent(escape(decodedString));
        } else if (section === "Copilot") {
          this.titleHtmlCopilot = decodeURIComponent(escape(title));
          this.contentHtmlCopilot = decodeURIComponent(escape(decodedString));
        }else if (section === "Grok") {
          this.titleHtmlGrok = decodeURIComponent(escape(title));
          this.contentHtmlGrok= decodeURIComponent(escape(decodedString));
        } else if (section === "Meta") {
          this.titleHtmlMeta = decodeURIComponent(escape(title));
          this.contentHtmlMeta = decodeURIComponent(escape(decodedString));
        } else if (section === "Manus") {
          this.titleHtmlManus = decodeURIComponent(escape(title));
          this.contentHtmlManus = decodeURIComponent(escape(decodedString));
        }else if (section === "Perplexity") {
          this.titleHtmlPerplexity = decodeURIComponent(escape(title));
          this.contentHtmlPerplexity = decodeURIComponent(escape(decodedString));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    changeTab(tab) {
    //   if (window.innerWidth <= 768) {
    //     this.isMenuVisible = false;
    //   }
      this.lang = localStorage.getItem("languages");

      const tabStates = {
       
        DeepSeek: false,ChatGPT: false,Gemini: false,Copilot: false,ungDungAI:false,
        Perplexity:false
      };

      this.performPostRequest(this.getPostId(tab), tab, this.lang);

      tabStates[tab] = true;
      this.activeTab = tab;
      this.showDeepSeek = tabStates.DeepSeek;
      this.showChatGPT = tabStates.ChatGPT;
      this.showGemini = tabStates.Gemini;
      this.showCopilot = tabStates.Copilot;
      this.showGrok = tabStates.Grok;
      this.showMeta = tabStates.Meta;
      this.showManus = tabStates.Manus;
      this.showPerplexity = tabStates.Perplexity;
    },
    getPostId(tab) {
      // Sử dụng một đối tượng để ánh xạ tab với id của bài viết tương ứng
      const postIdMap = {
       
        DeepSeek: "1739938050389coAMh",
        ChatGPT: "17399527093351ejje",
        Gemini: "1739952756556eTbQQ",
        Copilot: "1739962827008hEz13",
        Grok: "1741754513134ENfrm",
        Meta: "1741754576070zd1Fd",
        Manus: "1741754674991tS4fV",
        Perplexity: "17427429944913zXAp",
      };

      return postIdMap[tab];
    },
  },
};
</script>