<template>
  <header class="d-flex justify-content-between align-items-center">
    <label for="toggle-1" class="toggle-menu" @click="toggleMenu"
      ><ul>
        <li></li>
        <li></li>
        <li></li></ul
    ></label>
    <!-- <strong @click="toggleMenu"> &#9776;	</strong> -->
    <nav
      class="nav_center"
      style="padding: 0"
      :style="{ display: isMenuVisible ? 'block' : 'none' }"
    >
      <ul style="list-style: none">
        <li
          :class="{ topmenuactive: activeTab === 'lienhe' }"
          @click="changeTab('lienhe')"
        >
          <a id="urlBai3" href="ytegiaothongvadichvucong#vimasstaitro/"> Vimass {{
            currentLanguage.header_text_theDaNang_CTTaiCho
          }}</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'benhvien' }"
          @click="changeTab('benhvien')"
        >
          <a id="urlBenhVien" href="ytegiaothongvadichvucong#benhvien/">{{
            currentLanguage.header_text_theDaNang_bvTinhTp
          }}</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'yte' }"
          @click="changeTab('yte')"
        >
          <a id="urlYte" href="ytegiaothongvadichvucong#yte/">{{
            currentLanguage.header_text_theDaNang_phoCap_boyte
          }}</a>
        </li>
      
        <li
          :class="{ topmenuactive: activeTab === 'DichVuCong' }"
          @click="changeTab('DichVuCong')"
        >
          <a id="urlBai2" href="ytegiaothongvadichvucong#dichvucong/">{{
            currentLanguage.header_text_theDaNang_dichViCong
          }}</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'GiaoThong' }"
          @click="changeTab('GiaoThong')"
        >
          <a id="urlBai1" href="ytegiaothongvadichvucong#giaothong/">{{
            currentLanguage.header_text_diemThanhToan_diChuyen
          }}</a>
        </li>
       
      </ul>
    </nav>
    <div class="menu_right" style="display: flex">
      <div class="menu_co_right" style="display: flex">
        <a @click="changeLanguage('vi')" class="lang" style="padding: 5px"
          ><img src="img/img-item-vimass-2022/co/vi-VN.gif" />
        </a>
        <a @click="changeLanguage('en')" class="lang" style="padding: 5px"
          ><img style="width: 25px" src="img/img-item-vimass-2022/co/us.png" />
        </a>
        <a @click="changeLanguage('cn')" class="lang d-none" style="padding: 5px"
          ><img style="width: 25px" src="img/img-item-vimass-2022/co/cn.jpg" />
        </a>
        <a @click="changeLanguage('kr')" class="lang d-none" style="padding: 5px"
          ><img style="width: 25px" src="img/img-item-vimass-2022/co/kr.jpg" />
        </a>
        <a @click="changeLanguage('ja')" class="lang d-none" style="padding: 5px">
          <img style="width: 25px" src="img/img-item-vimass-2022/co/ja.jpg" />
        </a>

        <span
          class="clickShow"
          style="color: #303038; cursor: pointer; margin: auto"
          ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
        ></span>
      </div>
      <a id="urlHome" href="/" style="margin: 0 10px"
        ><img
          style="width: 35px; height: 35px"
          src="img/home-icon-white-8.jpg"
        />
      </a>
    </div>
  </header>
  <div class="wrapper row3">
    <main class="hoc container-fluid clear bv">
      <!-- giaoThong-->

      <div
        id="benhvien"
        v-show="showBenhVien"
        style="min-height: 500px"
      >
        <MainYTe></MainYTe>
      </div>

      <div
        id="yte"
        v-show="showYte"
        style="min-height: 500px"
      >
      <div class="title-bai-viet" v-html="titleYte"></div>
      <div v-html="contentYte"></div>
      </div>

      <div
        id="GiaoThong"
        v-show="showGiaoThong"
        style="min-height: 500px"
        class="padding-top15"
      >
        <div class="title-bai-viet" v-html="titleGiaoThong"></div>
        <div v-html="contentGiaoThong"></div>
      </div>
      <!-- DichVuCong -->
      <div
        id="DichVuCong"
        v-show="showDichVuCong"
        style="min-height: 500px; background-image: none !important"
        class="padding-top15"
      >
        <div class="title-bai-viet" v-html="titleDichVuCong"></div>
        <div v-html="contentDichVuCong"></div>
      </div>

      <!-- LIEN HE -->
      <div
        id="lienhe"
        v-show="showLienHe"
        style="min-height: 500px; background-image: none !important"
      >
        <div class="title-bai-viet" v-html="titleLienHe"></div>
        <div v-html="contentLienHe"></div>
      </div>
    </main>
  </div>
  <FooterView></FooterView>
</template>
  <script>
import FooterView from "@/views/Vimass/Footer/FooterView.vue";
import { languages } from "@/components/languages";
import { apiChiTietBaiViet } from "@/components/listService";
import axios from "axios";
import MainYTe from "./MainYTe.vue";

export default {
  components: {
    FooterView,
    MainYTe,
  },
  data() {
    return {
      activeTab: "lienhe",
      isMenuVisible: false,

      currentLanguage: languages.vi,
      lang: "",
      base64: "",
      base64Title: "",
      showYte: false,
      showGiaoThong: false,
      showBenhVien: false,
      showDichVuCong: false,
      showLienHe: true,

      //active tab
      // isActiveGioiThieu: true,isActiveYte:true, isActiveGiaoDuc:false,
      
      isActiveYte: false,
      isActiveGiaoThong: false,
      isActiveDichVuCong: false,
      isActiveBenhVien: false,
      isActiveLienHe: true,

      //title
      titleGioiThieu: "",
      titleYte: "",
      titleBenhVien: "",
      titleGiaoThong: "",
      titleDichVuCong: "",
      titleLienHe: "",

      //content
      contentGioiThieu: "",contentBenhVien: "",
      contentGiaoThong: "",
      contentDichVuCong: "",
      contentYte: "",
      contentLienHe: "",

      hasMounted: false,
    };
  },
  mounted() {
    if (window.innerWidth <= 768) {
      this.isMenuVisible = false;
    } else {
      this.isMenuVisible = true;
    }
    if (!this.hasMounted) {
      this.hasMounted = true; // Set the flag to true to indicate that mounted has been executed
      this.lang = localStorage.getItem("languages");
      if (this.lang == "vi") {
        document.title = "Vimass.vn - Y tế, Giao thông & dịch vụ công";
      } else if (this.lang == "en") {
        document.title = "Vimass.vn - Traffic & Public Services";
      } else if (this.lang == "cn") {
        document.title = "Vimass.vn - 交通及公共服务";
      } else if (this.lang == "kr") {
        document.title = "Vimass.vn - 교통 및 공공 서비스";
      }

       this.performPostRequest("173621689676614s2C", "lienhe", this.lang);
      // window.location.href = this.lang + "/ytegiaothongvadichvucong#yte/";

      document.getElementById("urlHome").setAttribute("href", this.lang);
      document
        .getElementById("urlYte")
        .setAttribute("href", this.lang + "/ytegiaothongvadichvucong#yte/");
      document
        .getElementById("urlBai1")
        .setAttribute("href", this.lang + "/ytegiaothongvadichvucong#giaothong/");
      document
        .getElementById("urlBai2")
        .setAttribute("href", this.lang + "/ytegiaothongvadichvucong#dichvucong/");
      document
        .getElementById("urlBai3")
        .setAttribute("href", this.lang + "/ytegiaothongvadichvucong#vimasstaitro/");
        document
        .getElementById("urlBenhVien")
        .setAttribute("href", this.lang + "/ytegiaothongvadichvucong#benhvien/");

        const hash = window.location.hash;
    if (hash && hash.includes("#vimasstaitro")) {
      this.changeTab("lienhe");
    } else if (hash && hash.includes("#benhvien")) {
      this.changeTab("benhvien");
    } else if (hash && hash.includes("#yte")) {
      this.changeTab("yte");
    } else if (hash && hash.includes("#giaothong")) {
      // this.changeTab("taitro");
      this.changeTab("GiaoThong");

    } else if (hash && hash.includes("#dichvucong")) {
      this.changeTab("DichVuCong");
    }  else {
      this.changeTab("lienhe");
    }
    }
  },
  created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
  methods: {
    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
      window.location.reload();
    },

    async performPostRequest(id, section) {
      this.lang = localStorage.getItem("languages");

      try {
        // Thực hiện POST request
        const response = await axios.post(apiChiTietBaiViet, {
          id: id,
          langId: "0",
        });
        if (this.lang === "vi") {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        } else if (this.lang === "en") {
          this.base64 = response.data.result.content_en;
          this.base64Title = response.data.result.title_en;
        } else if (this.lang === "cn") {
          this.base64 = response.data.result.content_cn;
          this.base64Title = response.data.result.title_cn;
        } else if (this.lang === "kr") {
          this.base64 = response.data.result.content_kr;
          this.base64Title = response.data.result.title_kr;
        } else {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        }

        var title = atob(this.base64Title);
        var decodedString = atob(this.base64);

        // Store content separately for each section
        if (section === "GiaoThong") {
          this.titleGiaoThong = decodeURIComponent(escape(title));
          this.contentGiaoThong = decodeURIComponent(escape(decodedString));
        } else if (section === "DichVuCong") {
          this.titleDichVuCong = decodeURIComponent(escape(title));
          this.contentDichVuCong = decodeURIComponent(escape(decodedString));
        } else if (section === "lienhe") {
          this.titleLienHe = decodeURIComponent(escape(title));
          this.contentLienHe = decodeURIComponent(escape(decodedString));
        } else if (section === "yte") {
          this.titleYte = decodeURIComponent(escape(title));
          this.contentYte= decodeURIComponent(escape(decodedString));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    toggleMenu() {
      this.isMenuVisible = true;
    },
    changeTab(tab) {
      if (window.innerWidth <= 768) {
        this.isMenuVisible = false;
      }
      this.lang = localStorage.getItem("languages");
      if (tab === "yte") {
        this.isActiveYte = true;
        this.isActiveGiaoThong = false;
        this.isActiveDichVuCong = false;
        this.isActiveBenhVien = false;
        this.isActiveLienHe = false;
        this.performPostRequest("1736242778844cCaoZ", "yte", this.lang);
      } else if (tab === "GiaoThong") {
        this.isActiveYte = false;

        this.isActiveGiaoThong = true;
        this.isActiveDichVuCong = false;
        this.isActiveBenhVien = false;
        this.isActiveLienHe = false;
        this.performPostRequest("1694514732643ut54a", "GiaoThong", this.lang);
      } else if (tab === "DichVuCong") {
        this.isActiveYte = false;

        this.isActiveGiaoThong = false;
        this.isActiveDichVuCong = true;
        this.isActiveBenhVien = false;
        this.isActiveLienHe = false;
        this.performPostRequest("1691491192754cs1o5", "DichVuCong", this.lang);
      } else if (tab === "lienhe") {
        this.isActiveYte = false;

        this.isActiveGiaoThong = false;
        this.isActiveDichVuCong = false;
        this.isActiveBenhVien = false;
        this.isActiveLienHe = true;
        this.performPostRequest("173621689676614s2C", "lienhe", this.lang);
      }else  if (tab === "benhvien") {
        this.isActiveYte = false;
        this.isActiveGiaoThong = false;
        this.isActiveDichVuCong = false;
        this.isActiveBenhVien = true;
        this.isActiveLienHe = false;
      } 

      this.activeTab = tab; // Cập nhật giá trị activeTab

      this.showYte = tab === "yte";
      this.showGiaoThong = tab === "GiaoThong";
      this.showDichVuCong = tab === "DichVuCong";
      this.showLienHe = tab == "lienhe";
      this.showBenhVien = tab == "benhvien";
    },
  },
};
</script>
  
  