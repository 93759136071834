<template>
  
  <div class="slider" :class="{ 'slide-next': isNext, 'slide-prev': isPrev }">
    <article class="one_quarter" v-for="(article, index) in displayedArticles" :key="index">
      <div style="height:auto">
        <a class="imgover"
        :href="getArticleLink(article)">
          <img style="max-width:100%;margin: 0 auto; border-radius:10px" :src="lang === 'vi' ? article.img_vi : 
          (lang === 'en' ? article.img_en 
          : lang === 'cn' ? article.img_cn 
          : lang === 'kr' ? article.img_kr 
          : article.img_vi)" alt="">
        </a>
      </div>
      <div class="excerpt">
        <h6 class="heading" style="margin-top: 10px; font-family: auto; font-size: 18px;"> {{ lang === 'vi' ? decodeBase64(article.title_vi) : 
        (lang === 'en' ? decodeBase64(article.title_en) 
        : lang === 'cn' ? decodeBase64(article.title_cn) 
        : lang === 'kr' ? decodeBase64(article.title_kr) 
        : decodeBase64(article.title_vi)) }}</h6>
        <p style="max-height: 200px; overflow: hidden;" > {{ lang === 'vi' ? decodeBase64(article.shortContent_vi) 
        : (lang === 'en' ? decodeBase64(article.shortContent_en) 
        : lang === 'cn' ? decodeBase64(article.shortContent_cn)  
        : lang === 'kr' ? decodeBase64(article.shortContent_kr)  
        : decodeBase64(article.shortContent_vi))  }}</p>
        <footer> <a :href="(lang === null || lang === '') ? 'vi/chi-tiet.html/' + article.id : lang + '/chi-tiet.html/' + article.id" >
          <i class="fas fa-angle-right"></i> {{ currentLanguage.Slide_docTiep }} </a> </footer>
      </div>
    </article>
    <div>
    </div>
    <!-- <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
    <button @click="nextPage" :disabled="currentPage === totalPages">Next</button> -->
    <div style=" display: flex;
            width: 100%;
            position: absolute;
            top: 20%;
            z-index: 1;
            justify-content: space-between;">
                <button @click="prevPage" :disabled="currentPage === 1" class="danhmuc-prev" style="width: 30px;
                height: 30px;
                border: none;
                border-radius: 30px;"><i style=" font-size: 30px;  color: #007aba;" class="fa fa-chevron-circle-left" aria-hidden="true"></i></button>
                <button @click="nextPage" :disabled="currentPage === totalPages" class="danhmuc-next" style="width: 30px;
                height: 30px;
                border: none;
                border-radius: 30px;"><i  style=" font-size: 30px;  color: #007aba;" class="fa fa-chevron-circle-right" aria-hidden="true"></i></button>
            </div>
  </div>
  </template>

  <script>
  import { decodeBase64 } from '@/components/VimassCrop';
  import { languages } from "@/components/languages";
import axios from 'axios';
import { apiLayChiTietDanhMuc } from '@/components/listService';

  export default {
  data() {
    return {
      articles: [],
      currentPage: 1,
      articlesPerPage: 4,
      lang:"",
      currentLanguage: languages.vi,
  
    };
  },
  created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
  mounted(){
    this.fetchData();
    this.lang = localStorage.getItem("languages");
    this.updateArticlesPerPage(); // Call to set initial value based on screen size
    window.addEventListener('resize', this.updateArticlesPerPage);
    },

  computed: {
    totalPages() {
      return Math.ceil(this.articles.length / this.articlesPerPage);
    },
    displayedArticles() {
      const start = (this.currentPage - 1) * this.articlesPerPage;
      const end = start + this.articlesPerPage;
      return this.articles.slice(start, end);
    },
  },
  methods: {
   async fetchData() {
      try {
        // Thực hiện POST request
        const response = await axios.post(apiLayChiTietDanhMuc, {
          id: "1541494373838is4v3",
          langId: 0,
          offset:0,
          limit:20
        });
        this.articles =  response.data.result.listDoccument;
        console.log("response ", response.data.result.listDoccument);
        
      }catch(error){
        console.error("Error:", error);
      }
      // import("@/components/json/baiviet.json").then((module) => {
      //   const data = JSON.parse(JSON.stringify(module.default));
      //   this.articles = data;
      //   // this.articles = data.filter(article => article.img_cn);
      // });
    },

    getArticleLink(article) {
    // Kiểm tra nếu article có danh mục cha là "1740736671675mXP8J"
    const hasEducationCategory = article.catParam?.some(cat => cat.idDanhMucCha === "1740736671675mXP8J");

    // Xác định base URL theo ngôn ngữ
    const baseUrl = (this.lang === null || this.lang === '') ? 'vi' : this.lang;
    
    // Nếu thuộc danh mục giáo dục, đổi sang "/giao-duc/"
    return hasEducationCategory 
      ? `${baseUrl}/giao-duc/${article.title_vi_short}`
      : `${baseUrl}/chi-tiet.html/${article.id}`;
  },
    decodeBase64(encodedString) {
      var decode = decodeBase64(encodedString);
      decode = decodeURIComponent(escape(decode));
      return decode;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
       
        this.currentPage++;
      } else {
    
        this.currentPage = 1; // Loop to the first page if on the last page
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
     
        this.currentPage--;
      } else {
       
        this.currentPage = this.totalPages; // Loop to the last page if on the first page
      }
    },
    updateArticlesPerPage() {
      // Adjust articlesPerPage based on screen size
      if (window.innerWidth <= 768) {
        this.articlesPerPage = 1; // Set to 1 for smaller screens (adjust as needed)
      } else {
        this.articlesPerPage = 4; // Set to default value for larger screens
      }
    },
    beforeDestroy() {
    window.removeEventListener('resize', this.updateArticlesPerPage);
  },
  },

};
</script>
<style scoped>
.slider {
  display: flex;
  transition: transform 0.5s ease-in-out !important;
}

.slide-next {
  transform: translateX(-100%) !important;
}

.slide-prev {
  transform: translateX(100%) !important;
}
</style>     



<!-- postman -->


<!-- http://118.69.84.243:28080/vmbank/services/categoryAdv/getDetailVer2
{
  "id": "1541494373838is4v3",
  "langId": 0,
  "offset":0,
  "limit":100
} -->