<template>
  <header class="d-flex justify-content-between align-items-center">
    <label for="toggle-1" class="toggle-menu" @click="toggleMenu"
      ><ul>
        <li></li>
        <li></li>
        <li></li></ul
    ></label>
    <!-- <strong @click="toggleMenu"> &#9776;	</strong> -->
    <nav
      class="nav_center"
      style="padding: 0"
      :style="{ display: isMenuVisible ? 'block' : 'none' }"
    >
      <ul style="list-style: none">
        <li
          :class="{ topmenuactive: activeTab === 'ThietBi' }"
          @click="changeTab('ThietBi')"
        >
          <a id="urlThietBi">{{
            currentLanguage.header_text_thietBi_thietBi
          }}</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'PhanCung' }"
          @click="changeTab('PhanCung')"
          style="display: none"
        >
          <a id="urlPhanCung"
            >{{ currentLanguage.header_text_ungDung_mayChu }}
          </a>
        </li>
        <!-- <li :class="{ topmenuactive: activeTab === 'ThietBi' }" @click="changeTab('ThietBi')" >
          <a id="urlThietBi">{{ currentLanguage.header_text_thietBi_thietBi }}</a>
        </li>

        <li :class="{ topmenuactive: activeTab === 'DaiLy' }" @click="changeTab('DaiLy')" >
          <a id="urlDaiLy" >{{ currentLanguage.header_text_thietBi_daiLy }}</a>
        </li> -->
        <!-- <li :class="{ topmenuactive: activeTab === 'PhanMem' }" @click="changeTab('PhanMem')">
          <a>{{ currentLanguage.footer_text_phanMem }}</a>
        </li> -->

        <li
          :class="{ topmenuactive: activeTab === 'DichVu' }"
          @click="changeTab('DichVu')"
        >
          <a id="urlDichVu">{{ currentLanguage.footer_text_dichVu }}</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'HoaDonDienTu' }"
          @click="changeTab('HoaDonDienTu')"
          style="display: none"
        >
          <a id="urlHoaDonDienTu">{{ currentLanguage.header_text_huongDan }}</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'DoiTac' }"
          @click="changeTab('DoiTac')"
        >
          <a id="urlDoiTac">{{
            currentLanguage.header_text_khachHang_doiTac
          }}</a>
        </li>

        <!-- <li :class="{ topmenuactive: activeTab === 'PhiDichVu' }" @click="changeTab('PhiDichVu')">
          <a id="urlPhiDichVu">{{ currentLanguage.header_text_phiDichVu }}</a>
        </li>
        

        <li :class="{ topmenuactive: activeTab === 'ChuKySo' }" @click="changeTab('ChuKySo')">
          <a id="urlChuKySo">{{ currentLanguage.header_text_chuKySo }}</a>
        </li>
        
        <li :class="{ topmenuactive: activeTab === 'HoaDonDienTu' }" @click="changeTab('HoaDonDienTu')">
          <a id="urlHoaDonDienTu">{{ currentLanguage.header_text_hoaDonDienTu }}</a>
        </li>
        <li :class="{ topmenuactive: activeTab === 'HopDongDienTu' }" @click="changeTab('HopDongDienTu')">
          <a id="urlHopDongDienTu">{{ currentLanguage.header_text_hopDongDienTu }}</a>
        </li> -->

        <li
          :class="{ topmenuactive: activeTab === 'DaiLy' }"
          @click="changeTab('DaiLy')"
        >
          <a id="urlDaiLy">{{ currentLanguage.header_text_lienHe }}</a>
        </li>
      </ul>
    </nav>
    <div class="menu_right" style="display: flex">
      <div class="menu_co_right" style="display: flex">
        <a @click="changeLanguage('vi')" class="lang" style="padding: 5px"
          ><img src="img/img-item-vimass-2022/co/vi-VN.gif" />
        </a>
        <a @click="changeLanguage('en')" class="lang" style="padding: 5px"
          ><img style="width: 25px" src="img/img-item-vimass-2022/co/us.png" />
        </a>
        <a
          @click="changeLanguage('cn')"
          class="lang d-none"
          style="padding: 5px"
          ><img style="width: 25px" src="img/img-item-vimass-2022/co/cn.jpg" />
        </a>
        <a
          @click="changeLanguage('kr')"
          class="lang d-none"
          style="padding: 5px"
          ><img style="width: 25px" src="img/img-item-vimass-2022/co/kr.jpg" />
        </a>
        <a
          @click="changeLanguage('ja')"
          class="lang d-none"
          style="padding: 5px"
          ><img style="width: 25px" src="img/img-item-vimass-2022/co/ja.jpg" />
        </a>

        <span
          class="clickShow"
          style="color: #303038; cursor: pointer; margin: auto"
          ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
        ></span>
      </div>
      <a id="urlHome" href="/" style="margin: 0 10px"
        ><img
          style="width: 35px; height: 35px"
          src="img/home-icon-white-8.jpg"
        />
      </a>
    </div>
  </header>
  <div class="row">
    <div class="wrapper row container-fluid pt-1 pb-2">
      <div v-show="activePhanCung" class="min-h--500p">
        <MainHeThongVue></MainHeThongVue>
      </div>

      <div v-show="activeThietBi" class="min-h--500p">
        <MainThietBiVue />
      </div>
      <div v-show="activeDaiLy" class="min-h--500p parent">
        <div v-html="titleHtml" class="title-bai-viet"></div>
        <div v-html="contentHtml"></div>
      </div>
      <div
        v-show="isActiveDoiTac"
        style="min-height: 500px"
        class="padding-top15"
      >
        <DoiTac></DoiTac>
      </div>
      <div v-show="activeDV" class="min-h--500p">
        <!-- <div v-html="titleHtml" class="title-bai-viet" ></div>
          <div v-html="contentHtml"></div> -->
        <MainDichVuVue> </MainDichVuVue>
      </div>

      <div v-show="activePhi" class="min-h--500p">
        <PhiDichVu />
      </div>

      <div v-show="activePM" class="min-h--500p">
        <!-- <div v-html="titleHtml" class="title-bai-viet"></div>
          <div v-html="contentHtml"></div> -->
        <main-phan-mem></main-phan-mem>
      </div>

      <div v-show="activeCKS" class="min-h--500p">
        <ChuKySo />
      </div>

      <div v-show="activeHoaDDT" class="min-h--500p">
        <div v-html="titleHtml" class="title-bai-viet"></div>
        <div v-html="contentHtml"></div>
      </div>

      <div v-show="activeHopDDT" class="min-h--500p">
        <div v-html="titleHtml" class="title-bai-viet"></div>
        <div v-html="contentHtml"></div>
      </div>
    </div>
  </div>
  <FooterView></FooterView>
</template>
  <script>
import FooterView from "@/views/Vimass/Footer/FooterView.vue";
import { languages } from "@/components/languages";
import MainThietBiVue from "../MainBaiVietCon/ThietBiVaDichVu/MainThietBi.vue";
// import MainDichVuVue from "../MainBaiVietCon/ThietBiVaDichVu/MainDichVu.vue";
import ChuKySo from "../MainBaiVietCon/ThietBiVaDichVu/DichVu/ChuKySo.vue";
import PhiDichVu from "../MainBaiVietCon/ThietBiVaDichVu/DichVu/PhiDichVu.vue";
import MainHeThongVue from "../MainBaiVietCon/ThietBiVaDichVu/MainHeThong.vue";
import { apiChiTietBaiViet } from "@/components/listService";
import axios from "axios";
import MainPhanMem from "../MainBaiVietCon/ThietBiVaDichVu/MainPhanMem.vue";
import MainDichVuVue from "../MainBaiVietCon/ThietBiVaDichVu/MainDichVu.vue";
import DoiTac from "../MainBaiVietCon/CongTy/DoiTac.vue";

export default {
  components: {
    MainThietBiVue,
    MainHeThongVue,
    FooterView,
    ChuKySo,
    PhiDichVu,
    MainPhanMem,
    MainDichVuVue,
    DoiTac,
  },
  data() {
    return {
      activeTab: "ThietBi",
      isMenuVisible: false,

      currentLanguage: languages.vi,
      lang: "",
      base64: "",
      base64Title: "",
      titleHtml: "",
      contentHtml: "",

      activePhanCung: false,
      showDoiTac: false,
      isActiveDoiTac: false,
      activeThietBi: true,
      activeDaiLy: false,
      activeDV: false,
      activePhi: false,
      activePM: false,
      activeCKS: false,
      activeHoaDDT: false,
      activeHopDDT: false,
      // isActiveHeThong: false,
      hasMounted: false,
    };
  },
  // watch: {
  //   $route(to, from) {
  //     // Xử lý khi URL thay đổi
  //     console.log("URL đã thay đổi từ", from.fullPath, "đến", to.fullPath);
  //     let url = to.fullPath;
  //     console.log("vois" + url);

  //     switch (true) {
  //       case url.indexOf("ThietBi") !== -1:
  //         this.changeTab("ThietBi");
  //         break;
  //       case url.indexOf("DichVu") !== -1:
  //         this.changeTab("DichVu");
  //         break;

  //       default:
  //     }
  //   },
  // },
  mounted() {
    if (window.innerWidth <= 768) {
      this.isMenuVisible = false;
    } else {
      this.isMenuVisible = true;
    }
    if (!this.hasMounted) {
      this.hasMounted = true; // Set the flag to true to indicate that mounted has been executed
      this.lang = localStorage.getItem("languages");
      if (this.lang == "vi") {
        document.title = "Vimass.vn - Máy chủ, thiết bị và dịch vụ";
      } else if (this.lang == "en") {
        document.title = "Vimass.vn -Server, Device & Services";
      } else if (this.lang == "cn") {
        document.title = "Vimass.vn - 设备和服务";
      } else if (this.lang == "kr") {
        document.title = "Vimass.vn - 장비 및 서비스";
      }
      // window.location.href = this.lang + "/thietbivadichvu#ThietBi/";
      document.getElementById("urlHome").setAttribute("href", this.lang);
      document
        .getElementById("urlThietBi")
        .setAttribute("href", this.lang + "/thietbivadichvu#thietbi");
      document
        .getElementById("urlDichVu")
        .setAttribute("href", this.lang + "/thietbivadichvu#dichvu");
      document
        .getElementById("urlDoiTac")
        .setAttribute("href", this.lang + "/thietbivadichvu#doitac");
      //  document.getElementById('urlPhiDichVu').setAttribute('href', this.lang + '/thietbivadichvu#PhiDichVu');
      //  document.getElementById('urlChuKySo').setAttribute('href', this.lang + '/thietbivadichvu#ChuKySo');
      document
        .getElementById("urlHoaDonDienTu")
        .setAttribute("href", this.lang + "/thietbivadichvu#huongdan");
      //  document.getElementById('urlHopDongDienTu').setAttribute('href', this.lang + '/thietbivadichvu#HopDongDienTu');
      document
        .getElementById("urlDaiLy")
        .setAttribute("href", this.lang + "/thietbivadichvu#lienhe");
    }
    const hash = window.location.hash;
    if (hash && hash.includes("#thietbi")) {
      this.changeTab("ThietBi");
    } else if (hash && hash.includes("#dichvu")) {
      this.changeTab("DichVu");
    } else if (hash && hash.includes("#doitac")) {
      this.changeTab("DoiTac");
    } else if (hash && hash.includes("#huongdan")) {
      this.changeTab("HoaDonDienTu");
    } else if (hash && hash.includes("#lienhe")) {
      this.changeTab("DaiLy");
    }
  },
  created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
  methods: {
    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
      // window.location.reload();
      window.location.href = "/" + lang + "/thietbivadichvu/";
    },
    toggleMenu() {
      this.isMenuVisible = true;
    },
    changeTab(tab) {
      if (window.innerWidth <= 768) {
        this.isMenuVisible = false;
      }
      this.lang = localStorage.getItem("languages");

      const tabStates = {
        PhanCung: false,
        DoiTac: false,
        ThietBi: false,
        DaiLy: false,
        DichVu: false,
        PhiDichVu: false,
        PhanMem: false,
        ChuKySo: false,
        HoaDonDienTu: false,
        HopDongDienTu: false,
      };
      tabStates[tab] = true;
      this.performPostRequest(this.getPostId(tab), tab, this.lang);

      this.activeTab = tab; // Cập nhật giá trị activeTab
      this.activePhanCung = tabStates.PhanCung;
      this.activeThietBi = tabStates.ThietBi;
      this.activeDaiLy = tabStates.DaiLy;
      this.isActiveDoiTac = tabStates.DoiTac;
      this.activeDV = tabStates.DichVu;
      this.activePhi = tabStates.PhiDichVu;

      this.activePM = tabStates.PhanMem;
      this.activeCKS = tabStates.ChuKySo;

      this.activeHoaDDT = tabStates.HoaDonDienTu;
      this.activeHopDDT = tabStates.HopDongDienTu;
    },
    getPostId(tab) {
      // Sử dụng một đối tượng để ánh xạ tab với id của bài viết tương ứng
      const postIdMap = {
        DaiLy: "1690272053288yg3c7", //1684299234996ruokw id đại lý
        DichVu: "1693993651381zumxd",
        PhanMem: "16945770557827dnkj",
        HoaDonDienTu: "1736247999466iSzYE",
        HopDongDienTu: "1660211941883iz4lu",
      };

      return postIdMap[tab];
    },
    async performPostRequest(id, section) {
      this.lang = localStorage.getItem("languages");

      try {
        // Thực hiện POST request
        const response = await axios.post(apiChiTietBaiViet, {
          id: id,
          langId: "0",
        });
        console.log(" response.data.result ", response.data.result);
        if (this.lang === "vi") {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        } else if (this.lang === "en") {
          this.base64 = response.data.result.content_en;
          this.base64Title = response.data.result.title_en;
        } else if (this.lang === "cn") {
          this.base64 = response.data.result.content_cn;
          this.base64Title = response.data.result.title_cn;
        } else if (this.lang === "kr") {
          this.base64 = response.data.result.content_kr;
          this.base64Title = response.data.result.title_kr;
        } else if (this.lang === "ja") {
          this.base64 = response.data.result.content_hi;
          this.base64Title = response.data.result.title_hi;
        } else {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        }

        var title = atob(this.base64Title);
        var decodedString = atob(this.base64);
        // Store content separately for each section
        if (section === "DaiLy") {
          this.titleHtml = decodeURIComponent(escape(title));
          this.contentHtml = decodeURIComponent(escape(decodedString));
        } else if (section === "DichVu") {
          this.titleHtml = decodeURIComponent(escape(title));
          this.contentHtml = decodeURIComponent(escape(decodedString));
        } else if (section === "PhanMem") {
          this.titleHtml = decodeURIComponent(escape(title));
          this.contentHtml = decodeURIComponent(escape(decodedString));
        } else if (section === "HoaDonDienTu") {
          this.titleHtml = decodeURIComponent(escape(title));
          this.contentHtml = decodeURIComponent(escape(decodedString));
        } else if (section === "HopDongDienTu") {
          this.titleHtml = decodeURIComponent(escape(title));
          this.contentHtml = decodeURIComponent(escape(decodedString));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  },
};
</script>
  
<style scoped>
nav ul li a {
  color: #ffffff !important;
  font-size: 18px;
  padding: 12px 10px;
  display: inline-block;
  font-family: auto;
  font-weight: 400;
  transition: all 0.5s ease 0s;
  text-decoration: none !important;
}
.min-h--500p {
  min-height: 500px;
}
</style>
  