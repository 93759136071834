<template>
  <header class="d-flex justify-content-between align-items-center">
    <label for="toggle-1" class="toggle-menu" @click="toggleMenu">
      <ul>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      </label>
    <nav
      class="nav_center" style="padding: 0" :style="{ display: isMenuVisible ? 'block' : 'none' }" >
      <ul style="list-style: none">
        <li :class="{ topmenuactive: activeTab === 'hethong' }" @click="changeTab('hethong')" >
          <a id="urlGioithieu" href="giaiphapchuyendoisodonvi#hethong/">Vimass Pass</a>
        </li>
        <li :class="{ topmenuactive: activeTab === 'htdinhdanh' }" @click="changeTab('htdinhdanh')">
          <a id="urlDinhDanh" href="giaiphapchuyendoisodonvi#khuonmat/"> {{ currentLanguage.header_text_KhuonMat}}</a>
        </li>
      
        <li :class="{ topmenuactive: activeTab === 'vantay' }" @click="changeTab('vantay')">
          <a id="urlVANTAY" href="giaiphapchuyendoisodonvi#vantay/">{{  currentLanguage.header_text_VanTay}}</a>
        </li>
        <li :class="{ topmenuactive: activeTab === 'htthanhtoan' }" @click="changeTab('htthanhtoan')">
          <a id="urlThanhToan" href="giaiphapchuyendoisodonvi#qr"> {{  currentLanguage.item_footer_8}}</a>
        </li>

      
      
        <!-- <li :class="{ topmenuactive: activeTab === 'vreader' }"  @click="changeTab('vreader')" >
          <a id="urlVreader" href="giaiphapchuyendoisodonvi#vreader/">{{ currentLanguage.body_text_thietbi_tb_VReader }}</a>
        </li> -->
        <li :class="{ topmenuactive: activeTab === 'vpass' }"  @click="changeTab('vpass')" >
          <a id="urlVpass" href="giaiphapchuyendoisodonvi#phathientuxa/">{{ currentLanguage.header_text_theChip }}</a>
        </li>
        <li :class="{ topmenuactive: activeTab === 'vreader' }"  @click="changeTab('vreader')" >
          <a id="urlVreader" href="giaiphapchuyendoisodonvi#chamthe/">{{ currentLanguage.header_text_RFID }}</a>
        </li>
        <li :class="{ topmenuactive: activeTab === 'tiengNoi' }" @click="changeTab('tiengNoi')">
          <a id="urlTiengNoi" href="giaiphapchuyendoisodonvi#tiengnoi/">{{  currentLanguage.header_text_ungDung_mayChuDonVi}}</a>
        </li>
        <!-- <li :class="{ topmenuactive: activeTab === 'vpass' }"  @click="changeTab('vpass')" >
          <a id="urlVpass" href="giaiphapchuyendoisodonvi#vpass/">pass.vimass.vn</a>
        </li> -->

        <!-- <li :class="{ topmenuactive: activeTab === 'lienhe' }" @click="changeTab('lienhe')" >
          <a id="urlLienHe" href="giaiphapchuyendoisodonvi#lienhe/">{{ currentLanguage.header_text_lienHe }}</a>
        </li> -->
      </ul>
    </nav>
    <div class="menu_right" style="display: flex">
      <div class="menu_co_right" style="display: flex">
    <a @click="changeLanguage('vi')" class="lang" style="padding: 5px"
      ><img src="img/img-item-vimass-2022/co/vi-VN.gif" />
    </a>
    <a @click="changeLanguage('en')" class="lang" style="padding: 5px"
      ><img style="width: 25px" src="img/img-item-vimass-2022/co/us.png" />
    </a>
    <a @click="changeLanguage('cn')" class="lang d-none" style="padding: 5px"
      ><img style="width: 25px" src="img/img-item-vimass-2022/co/cn.jpg" />
    </a>
    <a @click="changeLanguage('kr')" class="lang d-none" style="padding: 5px"
      ><img style="width: 25px ; " src="img/img-item-vimass-2022/co/kr.jpg" />
    </a>
    <a @click="changeLanguage('ja')" class="lang d-none" style="padding: 5px"
      ><img style="width: 25px ; " src="img/img-item-vimass-2022/co/ja.jpg" />
    </a>
 
    <span
      class="clickShow"
      style="color: #303038; cursor: pointer; margin: auto"
      ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
    ></span>
  
  </div>
      <a id="urlHome" href="/" style="margin: 0 10px">
        <img style="width: 35px; height: 35px" src="img/home-icon-white-8.jpg"/>
      </a>
    </div>
  </header>
  <div class="wrapper row3">
    <main class="hoc container-fluid clear bv">
      <!-- he thong -->
      <div
        id="hethong"
        v-show="showHethong"
        style="min-height: 500px"
        class="padding-top15"
      >
        <div class="title-bai-viet" v-html="titleHeThong"></div>
        <div v-html="contentHeThong"></div>
      </div>

      <div id="htthanhtoan" v-show="showThanhToan" style="min-height: 500px"  class="padding-top15">
        <div  class="title-bai-viet"  v-html="titleSystem"></div>
        <div v-html="contentSystem"></div>
      </div>

      <div id="htdinhdanh" v-show="showDinhDanh" style="min-height: 500px"  class="padding-top15">
        <div  class="title-bai-viet"  v-html="titleSystem"></div>
        <div v-html="contentSystem"></div>
      </div>

      <div id="tiengNoi" v-show="showTiengNoi" style="min-height: 500px"  class="padding-top15">
        <div  class="title-bai-viet"  v-html="titleSystem"></div>
        <div v-html="contentSystem"></div>
      </div>

      <!-- vpass -->
      <div
        id="vpass"
        v-show="showVpass"
        style="min-height: 500px; background-image: none !important"
        class="padding-top15"
      >
        <div class="title-bai-viet" v-html="titleVPass"></div>
        <div v-html="contentVPass"></div>
        <!-- <img-app-vpass></img-app-vpass> -->
      </div>
      <!-- vpos -->
      <div
        id="vpos"
        v-show="showVpos"
        style="min-height: 500px; background-image: none !important"
        class="padding-top15"
      >
        <div class="title-bai-viet" v-html="titleVPos"></div>
        <div v-html="contentVPos"></div>
        <img-app-v-pos></img-app-v-pos>
      </div>
      <!-- vcard -->
      <div
        id="vcard"
        v-show="showVcard"
        style="min-height: 500px; background-image: none !important"
        class="padding-top15"
      >
        <div class="title-bai-viet" v-html="titleVCard"></div>
        <div v-html="contentVCard"></div>
        <img-app-vcard></img-app-vcard>
      </div>
      <!-- vimass -->
      <div
        id="vivimass"
        v-show="showVimass"
        style="min-height: 500px; background-image: none !important"
        class="padding-top15"
      >
        <div class="title-bai-viet" v-html="titleVimass"></div>
        <div v-html="contentVimass"></div>
        <img-app-vi-vimass></img-app-vi-vimass>
      </div>
      <!-- vreader -->
      <div
        id="vreader"
        v-show="showVReader"
        style="min-height: 500px; background-image: none !important"
        class="padding-top15"
      >
        <div class="title-bai-viet" v-html="titleVReader"></div>
        <div v-html="contentVReader"></div>
      </div>

      
      <div
        id="ChuongTrinhThiDiemTaiTro"
        v-show="showChuongTrinhThiDiemTaiTro"
        style="min-height: 500px; background-image: none !important"
   
      >
       <MainChuongTrinhTaiTroVue></MainChuongTrinhTaiTroVue>
      </div>

      <!-- vcloud -->
      <div
        id="vcloud"
        v-show="showVCloud"
        style="min-height: 500px; background-image: none !important"
        class="padding-top15"
      >
        <div class="title-bai-viet" v-html="titleVCloud"></div>
        <div v-html="contentVCloud"></div>
      </div>
      <!-- vantay -->
      <div
        id="vantay"
        v-show="showVANTAY"
        style="min-height: 500px; background-image: none !important"
        class="padding-top15"
      >
        <div class="title-bai-viet" v-html="titleVantay"></div>
        <div v-html="contentVantay"></div>
      </div>
      <div
        id="api"
        v-show="showAPI"
        style="min-height: 500px; background-image: none !important"
        class="padding-top15"
      >
        <div class="title-bai-viet" v-html="titleAPI"></div>
        <div v-html="contentApi"></div>
      </div>
      <div
        id="nganhang"
        v-show="showNganHang"
        style="min-height: 500px; background-image: none !important"
      >
        <MainBaiVietNganHangVue></MainBaiVietNganHangVue>
      </div>
      <!-- thong tin -->
      <div id="thongtin" v-show="showThongTin" style="min-height: 500px; background-image: none !important">
        <MainHeThongVue></MainHeThongVue>
      </div>
      <!-- lien he -->
      <div
        id="lienhe"
        v-show="showLienHe"
        style="min-height: 500px; background-image: none !important"
        class="padding-top15"
      >
        <div class="title-bai-viet" v-html="titleLienHe"></div>
        <div v-html="contentLienHe"></div>
      </div>
    </main>
  </div>
  <FooterView></FooterView>
</template>
<script>
import FooterView from "@/views/Vimass/Footer/FooterView.vue";
import { languages } from "@/components/languages";
import { apiChiTietBaiViet } from "@/components/listService";
import axios from "axios";
import MainBaiVietNganHangVue from "../MainBaiVietCon/MainBaiVietNganHang.vue";
import MainHeThongVue from "../MainBaiVietCon/ThietBiVaDichVu/MainHeThong.vue";
import ImgAppVcard from '../imgApp/imgAppVcard.vue';
import ImgAppViVimass from '../imgApp/imgAppViVimass.vue';
import ImgAppVpass from '../imgApp/imgAppVpass.vue';
import ImgAppVPos from "../imgApp/imgAppVPos.vue";
// import MainLang from "../imgApp/imgAppVPos.vue";
import MainChuongTrinhTaiTroVue from './MainChuongTrinhTaiTro.vue';

export default {
  components: {
    FooterView,
    MainBaiVietNganHangVue,
    MainHeThongVue,
    ImgAppVcard,
    ImgAppViVimass,
    // eslint-disable-next-line vue/no-unused-components
    ImgAppVpass,
    ImgAppVPos,
    MainChuongTrinhTaiTroVue,
  },
  data() {
    return {
      activeTab: "hethong",
      isMenuVisible: false,

      currentLanguage: languages.vi,
      lang: "",
      base64: "",
      base64Title: "",

      //show
      showHethong: true,
      showThanhToan: false,
      showDinhDanh: false,
      showVpass: false,
      showVpos: false,
      showVCloud: false,
      showVcard: false,
      showVANTAY: false,
      showVReader: false,
      showNganHang: false,
      showThongTin: false,
      showLienHe: false,
      showVimass: false,
      showChuongTrinhThiDiemTaiTro :false,
      showAPI: false,
      //active tab
      isActiveTiengNoi:false,
      isActiveHeThong: true,
      isActiveVpass: false,
      isActiveVpos: false,
      isActiveVCloud: false,
      isActiveVcard: false,
      isActiveVimass: false,
      isAcitveVANTAY: false,
      isActiveVReader: false,
      isActiveNganHang: false,
      isActiveThongTin: false,
      isActiveLienHe: false,
      isActiveApi: false,
      isActiveChuongTrinhThiDiemTaiTro:false,

      //title
      titleHeThong: "",
      titleVPass: "",
      titleVPos: "",
      titleVCloud: "",
      titleVCard: "",
      titleVimass: "",
      titleVantay: "",
      titleVReader: "",
      titleThongTin: "",
      titleLienHe: "",
      titleAPI: "",

      //content
      contentHeThong: "",
      contentVPass: "",
      contentVPos: "",
      contentVCloud: "",
      contentVCard: "",
      contentVimass: "",
      contentVantay: "",
      contentVReader: "",
      contentThongTin: "",
      contentApi: "",
      contentLienHe: "",
      hasMounted: false,
      reloader: false,

      titleSystem:"",
      contentSystem:"",
    };
  },
  // watch: {
  //   $route(to, from) {
  //     // Xử lý khi URL thay đổi
  //     console.log("URL đã thay đổi từ", from.fullPath, "đến", to.fullPath);
  //     let url = to.fullPath;
  //     console.log("vois" + url);
   
  //     switch (true) {
  //       case url.indexOf("hethong") !== -1:
  //         this.changeTab("hethong");
  //         break;
  //       case url.indexOf("htthanhtoan") !== -1:
  //         this.changeTab("htthanhtoan");
  //         break;
  //       case url.indexOf("htdinhdanh") !== -1:
  //         this.changeTab("htdinhdanh");
  //         break;
  //         case url.indexOf("tiengNoi") !== -1:
  //         this.changeTab("tiengNoi");
  //         break;
  //       case url.indexOf("vpos") !== -1:
  //         this.changeTab("vpos");
  //         break;
  //       case url.indexOf("vpass") !== -1:
  //         this.changeTab("vpass");
  //         break;
  
  //       case url.indexOf("vcard") !== -1:
  //         this.changeTab("vcard");
  //         break;
  
  //       case url.indexOf("vreader") !== -1:
  //         this.changeTab("vreader");
  //         break;
  //       case url.indexOf("thedanang") !== -1:
  //         this.changeTab("vantay");
  //         break;
   
  //       case url.indexOf("lienhe") !== -1:
  //         this.changeTab("lienhe");
  //         break;

  //       default:
  //     }
  //   },
  // },
  mounted() {
    if (window.innerWidth <= 768) {
      this.isMenuVisible = false;
    } else {
      this.isMenuVisible = true;
    }
    if (!this.hasMounted) {
      this.hasMounted = true; // Set the flag to true to indicate that mounted has been executed
      this.lang = localStorage.getItem("languages");
      if (this.lang == "vi") {
        document.title = "Vimass.vn - Định danh xác thực";
      } else if (this.lang == "en") {
        document.title = "Vimass.vn - Authentication";
      } else if (this.lang == "cn") {
        document.title = "Vimass.vn - 认证标识符";
      } else if (this.lang == "kr") {
        document.title = "Vimass.vn - 인증 식별자";
      }
    //  window.location.href = this.lang + '/giaiphapchuyendoisodonvi#hethong/';
      this.performPostRequest("1729588812414vvwxl", "hethong", this.lang);
      document.getElementById("urlHome").setAttribute("href", this.lang);
      document.getElementById("urlGioithieu").setAttribute("href", this.lang + "/giaiphapchuyendoisodonvi#hethong/");
        document.getElementById("urlThanhToan").setAttribute("href", this.lang + "/giaiphapchuyendoisodonvi#qr/");
        document.getElementById("urlDinhDanh").setAttribute("href", this.lang + "/giaiphapchuyendoisodonvi#khuonmat/");
      document.getElementById("urlVpass").setAttribute("href", this.lang + "/giaiphapchuyendoisodonvi#thechip/");
      document.getElementById("urlVreader").setAttribute("href", this.lang + "/giaiphapchuyendoisodonvi#temrfid/");
      document.getElementById("urlVANTAY").setAttribute("href", this.lang + "/giaiphapchuyendoisodonvi#vantay/");
      // document.getElementById("urlLienHe").setAttribute("href", this.lang + "/giaiphapchuyendoisodonvi#lienhe/");
       document.getElementById("urlTiengNoi").setAttribute("href", this.lang + "/giaiphapchuyendoisodonvi#maychudonvi/");

        const hash = window.location.hash;
        console.log("hash ", hash)
    if (hash && hash.includes("#hethong")) {
      console.log("vaoday hethong");
      this.changeTab("hethong");
    } else if (hash && hash.includes("#qr")) {
      this.changeTab("thcs");
    } else if (hash && hash.includes("#khuonmat")) {
      this.changeTab("htdinhdanh");
    } else if (hash && hash.includes("#maychudonvi")) {
      this.changeTab("tiengNoi");
    } else if (hash && hash.includes("#lienhe")) {
      console.log("vaoday lienhe");
      this.changeTab("lienhe");
    } else if (hash && hash.includes("#thechip")) {
      this.changeTab("vpass");
    } else if (hash && hash.includes("#vantay")) {
      this.changeTab("vantay");
    } else if (hash && hash.includes("#temrfid")) {
      this.changeTab("vreader");
    }  else {
      console.log("vaoday");
      
      this.changeTab("hethong");
    }


    }

  
  },
  created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
  methods: {
    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
      window.location.href = "/" + lang + "/giaiphapchuyendoisodonvi#hethong/";
    },

    async performPostRequest(id, section) {
      this.lang = localStorage.getItem("languages");

      try {
        // Thực hiện POST request
        const response = await axios.post(apiChiTietBaiViet, {
          id: id,
          langId: "0",
        });
        console.log(" response.data.result ", response.data.result)
        if (this.lang === "vi") {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        } else if (this.lang === "en") {
          this.base64 = response.data.result.content_en;
          this.base64Title = response.data.result.title_en;
        } else if (this.lang === "cn") {
          this.base64 = response.data.result.content_cn;
          this.base64Title = response.data.result.title_cn;
        } else if (this.lang === "kr") {
          this.base64 = response.data.result.content_kr;
          this.base64Title = response.data.result.title_kr;
        }  else if (this.lang === "ja") {
          this.base64 = response.data.result.content_hi;
          this.base64Title = response.data.result.title_hi;
        }else {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        }

        var title = atob(this.base64Title);
        var decodedString = atob(this.base64);

        // Store content separately for each section
        if (section === "hethong") {
          this.titleHeThong = decodeURIComponent(escape(title));
          this.contentHeThong = decodeURIComponent(escape(decodedString));
        } else if (section === "vpass") {
          this.titleVPass = decodeURIComponent(escape(title));
          this.contentVPass = decodeURIComponent(escape(decodedString));
        } else if (section === "htthanhtoan") {
          this.titleSystem = decodeURIComponent(escape(title));
          this.contentSystem = decodeURIComponent(escape(decodedString));
        } else if (section === "htdinhdanh") {
          this.titleSystem = decodeURIComponent(escape(title));
          this.contentSystem = decodeURIComponent(escape(decodedString));
        }
        else if (section === "tiengNoi") {
          this.titleSystem = decodeURIComponent(escape(title));
          this.contentSystem = decodeURIComponent(escape(decodedString));
        }
      
        else if (section === "vpos") {
          this.titleVPos = decodeURIComponent(escape(title));
          this.contentVPos = decodeURIComponent(escape(decodedString));
        } else if (section === "vcloud") {
          this.titleVCloud = decodeURIComponent(escape(title));
          this.contentVCloud = decodeURIComponent(escape(decodedString));
        } else if (section === "vcard") {
          this.titleVCard = decodeURIComponent(escape(title));
          this.contentVCard = decodeURIComponent(escape(decodedString));
        } else if (section === "vivimass") {
          this.titleVimass = decodeURIComponent(escape(title));
          this.contentVimass = decodeURIComponent(escape(decodedString));
        } else if (section === "vantay") {
          this.titleVantay = decodeURIComponent(escape(title));
          this.contentVantay = decodeURIComponent(escape(decodedString));
        } else if (section === "vreader") {
          this.titleVReader = decodeURIComponent(escape(title));
          this.contentVReader = decodeURIComponent(escape(decodedString));
        }
        else if (section === "lienhe") {
          this.titleLienHe = decodeURIComponent(escape(title));
          this.contentLienHe = decodeURIComponent(escape(decodedString));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    toggleMenu() {
      this.isMenuVisible = true;
    },
    changeTab(tab) {
      if (window.innerWidth <= 768) {
        this.isMenuVisible = false;
      }
      this.lang = localStorage.getItem("languages");
      const tabStates = {
        // hethong: "1729588812414vvwxl",
        // htthanhtoan:"1710304613632p5sAE",
        // htdinhdanh:"1696472407793JGiel",
        // vpass: "1684483726409km5uj",
        // vpos: "1706500626843ceYPc",                       cũ
        // vcard: "169103571793214rn5",
        // vivimass: "1709871144698HILLP",
        // vcloud: "17068708760289t3Em",
        // vantay: "1546059256581d0h0r",

        hethong: "1729588812414vvwxl",
        htthanhtoan:"1729909572657UZoxV", //qr
        htdinhdanh:"1729909666077AjZvR",  // khuon mat
        vpass: "17299097914957Fxno",  // phát hiện từ xa
        vpos: "1706500626843ceYPc",
        vcard: "169103571793214rn5",
        vivimass: "1709871144698HILLP",
        vcloud: "17068708760289t3Em",
        tiengNoi: "1696472407793JGiel", // 1729916238976Lu85x tieesg nói
        vantay:"1729909719357f0zTr",
        vreader: "1729909761925gThvx",  //chạm thẻ
        nganhang: "",
        thongtin: "",
        lienhe: "1690272053288yg3c7",
        api: "1697101199141EwMrG",
        qr: "1729909572657UZoxV",
        khuonmat : "1729909666077AjZvR",
        chamthe:"1729909761925gThvx",
        phtuxa: "17299097914957Fxno",
      };
      this.resetActiveTabs();
      this[`isActive${tab.charAt(0).toUpperCase() + tab.slice(1)}`] = true;
      this.activeTab = tab;
      if (tabStates[tab]) {
        this.performPostRequest(tabStates[tab], tab, this.lang);
      }
      this.updateShowStates(tab);
    },
    resetActiveTabs() {
      this.isActiveHethong =
        this.isActiveVpass =
        this.isActiveVcard =
        this.isAcitveVANTAY =
        this.isActiveVReader =
        this.isActiveVimass =
        this.isActiveNganHang =
        this.isActiveThongTin =
        this.isActiveLienHe =
        this.isActiveVpos =
        this.isActiveVCloud =
        this.isActiveApi =
      this.isActiveTiengNoi =
        this.isActiveChuongTrinhThiDiemTaiTro = 
          false;
    },
    updateShowStates(tab) {
      this.showHethong = tab === "hethong";
      this.showThanhToan = tab === "htthanhtoan";
      this.showDinhDanh = tab === "htdinhdanh";
      this.showVpass = tab === "vpass";
      this.showVpos = tab === "vpos";
      this.showVCloud = tab === "vcloud";
      this.showVReader = tab === "vreader";
      this.showVimass = tab === "vivimass";
      this.showVcard = tab === "vcard";
      this.showVANTAY = tab === "vantay";
      this.showNganHang = tab === "nganhang";
      this.showThongTin = tab === "thongtin";
      this.showLienHe = tab == "lienhe";
      this.showTiengNoi = tab == "tiengNoi";
 
    
    },
  },
};
</script>

