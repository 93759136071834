<template>
      <nav class="nav_center2" style="padding: 0; background: cadetblue">
    <ul class="" style="list-style: none; display: none; " >
          <li  style="border-right: 1px solid rgb(167 167 167)" :class="{ topmenuactive: activeTab === 'PhiDichVu' }" @click="changeTab('PhiDichVu')">
          <a id="urlPhiDichVu" style="color: black;" >{{ currentLanguage.header_text_phiDichVu }}</a>
        </li>
        <!-- <li :class="{ topmenuactive: activeTab === 'PhanMem' }" @click="changeTab('PhanMem')">
          <a class="urlPhanMem" >{{ currentLanguage.footer_text_phanMem }}</a>
        </li> -->
        <li  style="border-right: 1px solid rgb(167 167 167)" :class="{ topmenuactive: activeTab === 'ChuKySo' }" @click="changeTab('ChuKySo')">
          <a id="urlChuKySo" style="color: black;">{{ currentLanguage.header_text_chuKySo }}</a>
        </li>
        <li  style="border-right: 1px solid rgb(167 167 167)" :class="{ topmenuactive: activeTab === 'HoaDonDienTu' }" @click="changeTab('HoaDonDienTu')">
          <a id="urlHoaDonDienTu" style="color: black;">{{ currentLanguage.header_text_hoaDonDienTu }}</a>
        </li>
        <li  style="border-right: 1px solid rgb(167 167 167)" :class="{ topmenuactive: activeTab === 'HopDongDienTu' }" @click="changeTab('HopDongDienTu')">
          <a id="urlHopDongDienTu" style="color: black;">{{ currentLanguage.header_text_hopDongDienTu }}</a>
        </li>
        </ul>
      </nav>
      <div class="wrapper row3">
      <main class="hoc container-fluid clear bv">
        <div class="wrapper row3">
          <main class="hoc container-fluid clear bv">
            <!-- PhiDichVu -->
            <div id="PhiDichVu"  v-show="showPhiDichVu"   style="min-height: 500px"   class="padding-top15"  >
             <PhiDichVuVue></PhiDichVuVue>
            </div>
  <!-- PhanMem -->
            <div
              id="PhanMem"  v-show="showPhanMem"  style="min-height: 500px; background-image: none !important" class="padding-top15">
              <div class="title-bai-viet" v-html="titlePhanMem"></div>
              <div v-html="contentPhanMem"></div>
            </div>
  <!-- ChuKySo -->
            <div id="vcard"  v-show="showChuKySo"   style="min-height: 500px; background-image: none !important"   class="padding-top15">
              <ChuKySo></ChuKySo>
                <div class="title-bai-viet" v-html="titleChuKySo"></div>
              <div v-html="contentChuKySo"></div>
            </div>
   <!-- HoaDonDienTu -->
            <div
              id="HoaDonDienTu"
              v-show="showHoaDonDienTu"
              style="min-height: 500px; background-image: none !important"
              class="padding-top15"
            >
            <div class="title-bai-viet" v-html="titleHoaDonDienTu"></div>
              <div v-html="contentHoaDonDienTu"></div>
          </div>
   <!-- tdn -->
            <div id="HopDongDienTu" v-show="showHopDongDienTu"  style="min-height: 500px; background-image: none !important"  class="padding-top15" >
              <div class="title-bai-viet" v-html="titleHopDongDienTu"></div>
              <div v-html="contentHopDongDienTu"></div>
            </div>
  
          </main>
        </div>
      </main>
    </div>
  
  </template>
  <script>
  import { languages } from "@/components/languages";
  import { apiChiTietBaiViet } from "@/components/listService";
  import axios from "axios";
import PhiDichVuVue from './DichVu/PhiDichVu.vue';
import ChuKySo from './DichVu/ChuKySo.vue';
  
  export default {
    components: {

        PhiDichVuVue ,
        ChuKySo
    },
    data() {
      return {
        activeTab: 'PhiDichVu',
  
        currentLanguage: languages.vi,
        lang: "",
        base64: "",
        base64Title: "",
  
        //show
        showPhiDichVu: true,
        showPhanMem: false,
        showChuKySo: false,
        showHoaDonDienTu: false,
        showHopDongDienTu:false,
        
        //active tab
        isActivePhiDichVu: true,
        isActivePhanMem: false,
        isActiveChuKySo: false,
        isAcitveHoaDonDienTu: false,
        isActiveHopDongDienTu:false,
  
        //title
        titlePhiDichVu: "",
        titlePhanMem: "",
        titleChuKySo: "",
        titleHoaDonDienTu: "",
        titleHopDongDienTu:"",
  
        //content
        contentPhiDichVu: "",
        contentPhanMem: "",
        contentChuKySo: "",
        contentHoaDonDienTu:"",
        contentHopDongDienTu:"",

        hasMounted: false,
      };
    },
    mounted() {
      if (window.innerWidth <= 768) {
      this.isMenuVisible = false;
    } else {
      this.isMenuVisible = true;
    }
    if (!this.hasMounted) {
      this.hasMounted = true;
      this.lang = localStorage.getItem("languages");  
      this.performPostRequest("1695975526923tLcPE", "PhiDichVu", this.lang);
      document.getElementById("urlPhiDichVu").setAttribute("href", this.lang+ "/thietbivadichvu#dichvu/phidichvu");
      document.getElementById("urlChuKySo").setAttribute("href", this.lang+ "/thietbivadichvu#dichvu/chukyso");
      document.getElementById("urlHoaDonDienTu").setAttribute("href", this.lang+ "/thietbivadichvu#dichvu/hoadondientu");
      document.getElementById("urlHopDongDienTu").setAttribute("href", this.lang+ "/thietbivadichvu#dichvu/hopdongdientu");
    //   const hash = window.location.hash;
    // if (hash && hash.includes("#phidichvu")) {
    //   this.changeTab("PhiDichVu");
    // } else if (hash && hash.includes("#chukyso")) {
    //   this.changeTab("ChuKySo");
    // } else if (hash && hash.includes("#hoadondientu")) {
    //   this.changeTab("HoaDonDienTu");
    // } else if (hash && hash.includes("#hopdongdientu")) {
    //   // this.changeTab("taitro");
    //   this.changeTab("HopDongDienTu");

    // }  else {
    //   this.changeTab("PhiDichVu");
    // }
    const fragmentTab = window.location.hash.substring(1);
      console.log("fragmentTab "+ fragmentTab);
      
      if (fragmentTab === "dichvu/phidichvu") {
        this.changeTab("PhiDichVu");
      } else if (fragmentTab === "dichvu/chukyso") {
        this.changeTab("ChuKySo");
      } else if (fragmentTab === "dichvu/hoadondientu") {
        this.changeTab("HoaDonDienTu");
      } else if (fragmentTab === "dichvu/hopdongdientu") {
        this.changeTab("HopDongDienTu");
      }  else {
        // Nếu không phù hợp với bất kỳ tab nào, mặc định hiển thị tab gioiThieu
        this.changeTab("PhiDichVu");
      }
  }
    },
    created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
    methods: {
      changeLanguage(lang) {
        this.currentLanguage = languages[lang];
        localStorage.setItem("languages", lang);
      window.location.reload();
      },
  
      async performPostRequest(id, section) {
        this.lang = localStorage.getItem("languages");
  
        try {
          // Thực hiện POST request
          const response = await axios.post(apiChiTietBaiViet, {
            id: id,
            langId: "0",
          });
          if (this.lang === "vi") {
            this.base64 = response.data.result.content_vi;
            this.base64Title = response.data.result.title_vi;
          } else if (this.lang === "en") {
            this.base64 = response.data.result.content_en;
            this.base64Title = response.data.result.title_en;
          }else if (this.lang === "cn") {
          this.base64 = response.data.result.content_cn;
          this.base64Title = response.data.result.title_cn;
        }else if (this.lang === "kr") {
          this.base64 = response.data.result.content_kr;
          this.base64Title = response.data.result.title_kr;
        }
  
          var title = atob(this.base64Title);
          var decodedString = atob(this.base64);
  
          // Store content separately for each section
          if (section === "PhanMem") {
            this.titlePhanMem = decodeURIComponent(escape(title));
            this.contentPhanMem = decodeURIComponent(escape(decodedString));
          }else if (section === "ChuKySo") {
            this.titleChuKySo = decodeURIComponent(escape(title));
            this.contentChuKySo = decodeURIComponent(escape(decodedString));
          }else if (section === "HoaDonDienTu") {
            this.titleHoaDonDienTu = decodeURIComponent(escape(title));
            this.contentHoaDonDienTu = decodeURIComponent(escape(decodedString));
          }else if (section === "HopDongDienTu") {
            this.titleHopDongDienTu = decodeURIComponent(escape(title));
            this.contentHopDongDienTu = decodeURIComponent(escape(decodedString));
          }
        } catch (error) {
          console.error("Error:", error);
        }
      },
      changeTab(tab) {
        this.lang = localStorage.getItem("languages");
  
        if (tab === 'PhiDichVu') {
          this.isActivePhiDichVu = true;
          this.isActivePhanMem = false;
          this.isActiveChuKySo = false;
          this.isAcitveHoaDonDienTu = false;
          this.isAcitveHoaDonDienTu =false;
  
        } else if (tab === 'PhanMem') {
          this.isActivePhiDichVu = false;
          this.isActivePhanMem = true;
          this.isActiveChuKySo = false;
          this.isAcitveHoaDonDienTu = false;
          this.isAcitveHoaDonDienTu =false;
          this.performPostRequest("1655883850276iscv7", 'PhanMem', this.lang);
  
        } else if (tab === 'ChuKySo') {
          this.isActivePhiDichVu = false;
          this.isActivePhanMem = false;
          this.isActiveChuKySo = true;
          this.isAcitveHoaDonDienTu = false;
          this.isAcitveHoaDonDienTu =false;
          this.performPostRequest("1660190991426mbiuc", 'ChuKySo', this.lang);
  
        } else if (tab === 'HoaDonDienTu') {
          this.isActivePhiDichVu = false;
          this.isActivePhanMem = false;
          this.isActiveChuKySo = false;
          this.isAcitveHoaDonDienTu = true;
          this.isAcitveHoaDonDienTu =false;
          this.performPostRequest("1660193742660ftll6", 'HoaDonDienTu', this.lang);
  
        }else if (tab === 'HopDongDienTu') {
          this.isActivePhiDichVu = false;
          this.isActivePhanMem = false;
          this.isActiveChuKySo = false;
          this.isAcitveHoaDonDienTu = false;
          this.isAcitveHoaDonDienTu =true;
          this.performPostRequest("1660211941883iz4lu", 'HopDongDienTu', this.lang);
        }
     
  
        this.activeTab = tab; // Cập nhật giá trị activeTab
  
        this.showPhiDichVu = tab === 'PhiDichVu';
        this.showPhanMem = tab === 'PhanMem';
        this.showChuKySo = tab === 'ChuKySo';
        this.showHoaDonDienTu = tab === 'HoaDonDienTu';
        this.showHopDongDienTu = tab === 'HopDongDienTu';
      },
   
    },
  };
  </script>