<template>
  <header class="d-flex justify-content-between align-items-center">
    <label for="toggle-1" class="toggle-menu" @click="toggleMenu">
      <ul>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </label>
    <!-- <strong @click="toggleMenu"> &#9776;	</strong> -->
    <nav
      class="nav_center"
      style="padding: 0"
      :style="{ display: isMenuVisible ? 'block' : 'none' }"
    >
      <ul style="list-style: none">
        <li
          :class="{ topmenuactive: activeTab === 'AITaiLopHoc' }"
          @click="changeTab('AITaiLopHoc')"
        >
          <a id="urlAITaiLopHoc">
            {{ currentLanguage.header_text_theDaNang_AITaiLopHoc }}
          </a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'thidiem' }"
          @click="changeTab('thidiem')"
        >
          <a id="urlThiDiem">
            {{ currentLanguage.header_text_theDaNang_CTTaiCho }}
          </a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'taitro' }"
          @click="changeTab('taitro')"
          style="display: none"
          @mouseover="showSubMenu = true"
          @mouseleave="showSubMenu = false"
        >
          <a id="urlTaiTro1">
            {{ currentLanguage.header_text_theDaNang_truongDuocTaiTro }}</a
          >
          <ul class="submenu" v-if="showSubMenu">
            <li>
              <a href="/vi/chi-tiet.html/1739437929192QXC7O">TH Đại Đồng</a>
            </li>
            <li>
              <a href="/vi/giaoduc#truongduoctaitro/tieuhocphungxab"
                >TH Phùng Xá B</a
              >
            </li>
            <li>
              <a href="/vi/chi-tiet.html/1693993651381zumxd">THCS Đại Đồng</a>
            </li>
            <li>
              <a href="/vi/giaoduc#truongduoctaitro/thcsphuchoa"
                >THCS Phúc Hòa</a
              >
            </li>
            <li>
              <a href="/vi/giaoduc#truongduoctaitro/thcsthachthat"
                >THCS Thạch Thất</a
              >
            </li>
            <li>
              <a href="/vi/giaoduc#truongduoctaitro/thcsyenbinh"
                >THCS Yên Bình</a
              >
            </li>
            <li>
              <a href="/vi/chi-tiet.html/1739437716917fEPkW">THPT Thạch Thất</a>
            </li>
            <li>
              <a href="/vi/giaoduc#truongduoctaitro/thpthaibatrung"
                >THPT Hai Bà Trưng</a
              >
            </li>
            <li>
              <a href="/vi/giaoduc#truongduoctaitro/thpthongduc"
                >THPT Hồng Đức</a
              >
            </li>
            <li>
              <a href="/vi/giaoduc#truongduoctaitro/thptngoctao"
                >THPT Ngọc Tảo</a
              >
            </li>
            <li>
              <a href="/vi/giaoduc#truongduoctaitro/thptphungkhackhoan"
                >THPT Phùng Khắc Khoan</a
              >
            </li>
            <li>
              <a href="/vi/giaoduc#truongduoctaitro/thptvancon">THPT Vân Côn</a>
            </li>
          </ul>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'caodang' }"
          @click="changeTab('caodang')"
          style="display: none"
        >
          <a id="urlBai4">{{
            currentLanguage.header_text_theDaNang_mamNonTieuHoc
          }}</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'th' }"
          @click="changeTab('th')"
          @mouseover="showSubMenu = true"
          @mouseleave="showSubMenu = false"
        >
          <a id="urlBai1">
            {{ currentLanguage.header_text_theDaNang_mamNonTieuHoc }}
          </a>
          <!-- <ul class="submenu" v-if="showSubMenu">
            <li><a  href="/vi/chi-tiet.html/1739437929192QXC7O">Tiểu học Đại Đồng</a></li>
            <li><a  href="/vi/giaoduc#tieuhoc">Tiểu học Hương Ngải</a></li>
            <li><a  href="/vi/giaoduc#tieuhoc">Tiểu học Yên Bình A</a></li>
            <li><a  href="/vi/giaoduc#tieuhoc">Tiểu học Yên Bình B</a></li>
            <li><a  href="/vi/giaoduc#tieuhoc">Tiểu học Phùng Xá B</a></li>
            <li><a  href="/vi/giaoduc#tieuhoc">Tiểu học Minh Hà A</a></li>
            <li><a  href="/vi/giaoduc#tieuhoc">Tiểu học Phụng Thượng</a></li>
            <li><a  href="/vi/giaoduc#tieuhoc">Tiểu học Hai Bà Trưng</a></li>
          </ul> -->
        </li>

        <li
          :class="{ topmenuactive: activeTab === 'thcs' }"
          @click="changeTab('thcs')"
          @mouseover="showSubMenu = true"
          @mouseleave="showSubMenu = false"
        >
          <a id="urlBai2">
            {{ currentLanguage.header_text_theDaNang_thcsThpt }}
          </a>
          <!-- <ul class="submenu" v-if="showSubMenu">

            <li><a  href="/vi/chi-tiet.html/1693993651381zumxd">THCS Đại Đồng</a></li>
            <li><a  href="/vi/giaoduc#trunghoccoso/phuchoa">THCS Phúc Hòa</a></li>
            <li><a  href="/vi/giaoduc#trunghoccoso/thachthat">THCS Thạch Thất</a></li>
            <li><a  href="/vi/giaoduc#trunghoccoso/yenbinh">THCS Yên Bình</a></li>

          </ul> -->
        </li>

        <li
          :class="{ topmenuactive: activeTab === 'thpt' }"
          @click="changeTab('thpt')"
          @mouseover="showSubMenu = true"
          @mouseleave="showSubMenu = false"
        >
          <a id="urlBai3"> {{ currentLanguage.header_text_theDaNang_Thpt }}</a>
          <!-- <ul class="submenu" v-if="showSubMenu">
            <li><a target="_blank"  href="/vi/chi-tiet.html/1739437716917fEPkW">THPT Thạch Thất</a></li>
            <li><a target="_blank"  href="/vi/giaoduc#trunghocphothong/#">THPT Hai Bà Trưng</a></li>
            <li><a target="_blank"  href="/vi/giaoduc#trunghocphothong/#">THPT Hồng Đức</a></li>
            <li><a target="_blank"  href="/vi/giaoduc#trunghocphothong/#">THPT Ngọc Tảo</a></li>
            <li><a target="_blank"  href="/vi/giaoduc#trunghocphothong/#">THPT Phùng Khắc Khoan</a></li>
            <li><a target="_blank"  href="/vi/giaoduc#trunghocphothong/#">THPT Vân Côn</a></li>
          </ul> -->
        </li>

        <!-- <li :class="{ topmenuactive: activeTab === 'taitro' }" @click="changeTab('taitro')" >
            <a id="urlTaiTro" > {{ currentLanguage.header_text_theDaNang_ctTaiTro}}</a>
          </li> -->
        <!-- <li :class="{ topmenuactive: activeTab === 'daihoc' }" @click="changeTab('daihoc')" >
            <a id="urlBai5"> {{ currentLanguage.header_text_theDaNang_daiHoc}} </a>
          </li> -->
        <!-- --------------------------- -->
        <li
          :class="{ topmenuactive: activeTab === 'Yte' }"
          @click="changeTab('Yte')"
          style="display: none"
        >
          <a id="yte_urlBai1">{{
            currentLanguage.header_text_diemThanhToan_sucKhoe
          }}</a>
        </li>

        <li
          :class="{ topmenuactive: activeTab === 'BenhVienTinh' }"
          @click="changeTab('BenhVienTinh')"
          style="display: none"
        >
          <a id="yte_urlBai2">{{
            currentLanguage.header_text_theDaNang_TCNguoiNgheo_Tinhthanhpho
          }}</a>
        </li>

        <li
          :class="{ topmenuactive: activeTab === 'BenhVienHuyen' }"
          @click="changeTab('BenhVienHuyen')"
          style="display: none"
        >
          <a id="yte_urlBai3">{{
            currentLanguage.header_text_theDaNang_QuanHuyen
          }}</a>
        </li>

        <li
          :class="{ topmenuactive: activeTab === 'CoSoYTeXa' }"
          @click="changeTab('CoSoYTeXa')"
          style="display: none"
        >
          <a id="yte_urlBai4">{{
            currentLanguage.header_text_theDaNang_phoCap_boyte
          }}</a>
        </li>

        <li
          :class="{ topmenuactive: activeTab === 'liencap' }"
          @click="changeTab('liencap')"
        >
          <a id="urlLienCap">Liên cấp</a>
        </li>

        <li
          :class="{ topmenuactive: activeTab === 'taitro' }"
          @click="changeTab('taitro')"
        >
          <a id="urlTaiTro">
            {{ currentLanguage.header_text_theDaNang_ctTaiTro }}</a
          >
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'lienhe' }"
          @click="changeTab('lienhe')"
        >
          <a id="urlLienHe">{{ currentLanguage.header_text_lienHe }}</a>
        </li>

        <li
          :class="{ topmenuactive: activeTab === 'Perplexity' }"
          @click="changeTab('Perplexity')"
        >
          <a id="urlPerplexity">Thư viện</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'ungDungAI' }"
          @click="changeTab('ungDungAI')"
        >
          <a id="urlUngDungAI">Ứng dụng AI phổ biến</a>
        </li>
        <li class="d-none"
          :class="{ topmenuactive: activeTab === 'Perplexity' }"
          @click="changeTab('Perplexity')"
        >
          <a id="urlPerplexity">Perplexity</a>
        </li>
        <li class="d-none"
          :class="{ topmenuactive: activeTab === 'Gemini' }"
          @click="changeTab('Gemini')"
        >
          <a id="urlGemini">Gemini</a>
        </li>
        <li class="d-none"
          :class="{ topmenuactive: activeTab === 'ChatGPT' }"
          @click="changeTab('ChatGPT')"
        >
          <a id="urlChatGPT">ChatGPT</a>
        </li>
        <li class="d-none"
          :class="{ topmenuactive: activeTab === 'DeepSeek' }"
          @click="changeTab('DeepSeek')"
        >
          <a id="urlDeepSeek">DeepSeek</a>
        </li>
       
        <li class="d-none"
          :class="{ topmenuactive: activeTab === 'Grok' }"
          @click="changeTab('Grok')"
        >
          <a id="urlGrok">Grok</a>
        </li>
        <li class="d-none"
          :class="{ topmenuactive: activeTab === 'Copilot' }"
          @click="changeTab('Copilot')"
        >
          <a id="urlCopilot">Copilot</a>
        </li>
       
        <li class="d-none"
          :class="{ topmenuactive: activeTab === 'Meta' }"
          @click="changeTab('Meta')"
        >
          <a id="urlMeta">Meta AI</a>
        </li>
        <li class="d-none"
          :class="{ topmenuactive: activeTab === 'Manus' }"
          @click="changeTab('Manus')"
        >
          <a id="urlManus">Manus</a>
        </li>
      </ul>
    </nav>
    <div class="menu_right" style="display: flex">
      <a @click="changeLanguage('vi')" class="lang" style="padding: 5px"
        ><img src="img/img-item-vimass-2022/co/vi-VN.gif" />
      </a>
      <a @click="changeLanguage('en')" class="lang" style="padding: 5px"
        ><img style="width: 25px" src="img/img-item-vimass-2022/co/us.png" />
      </a>
      <a @click="changeLanguage('cn')" class="lang d-none" style="padding: 5px"
        ><img style="width: 25px" src="img/img-item-vimass-2022/co/cn.jpg" />
      </a>
      <a @click="changeLanguage('kr')" class="lang d-none" style="padding: 5px"
        ><img style="width: 25px" src="img/img-item-vimass-2022/co/kr.jpg" />
      </a>
      <a @click="changeLanguage('ja')" class="lang d-none" style="padding: 5px"
        ><img style="width: 25px" src="img/img-item-vimass-2022/co/ja.jpg" />
      </a>

      <span
        class="clickShow"
        style="color: #303038; cursor: pointer; margin: auto"
        ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
      ></span>

      <a id="urlHome" href="/" style="margin: 0 10px">
        <img
          style="width: 35px; height: 35px"
          src="img/home-icon-white-8.jpg"
        />
      </a>
    </div>
  </header>
  <div class="row3">
    <main class="hoc container-fluid clear bv">
      <div v-show="showAITaiLopHoc" class="padding-top15 content">
        <div class="title-bai-viet" v-html="titleHtmlAITaiLopHoc"></div>
        <div v-html="contentHtmlAITaiLopHoc"></div>
      </div>

      <div v-show="showThiDiem" class="padding-top15 content">
        <div class="title-bai-viet" v-html="titleHtml"></div>
        <div v-html="contentHtml"></div>
      </div>

      <div v-show="showTaiTro" class="padding-top15 content">
        <div class="title-bai-viet" v-html="titleHtmlTruongTaiTro"></div>
        <div v-html="contentHtmlTruongTaiTro"></div>
      </div>
      <div v-show="showUngDungAI" >
        <UngDungAI></UngDungAI>
      </div>

      <!-- tieu hoc-->
      <div v-show="showTieuHoc" class="padding-top15 content">
        <div class="title-bai-viet" v-html="titleHtmlTH"></div>
        <div v-html="contentHtmlTH"></div>
      </div>
      <!-- thcs -->
      <div v-show="showTHCS" class="padding-top15 content">
        <div class="title-bai-viet" v-html="titleHtmlTHCS"></div>
        <div v-html="contentHtmlTHCS"></div>
      </div>
      <!-- THPT -->
      <div v-show="showTHPT" class="padding-top15 content">
        <div class="title-bai-viet" v-html="titleHtmlTHPT"></div>
        <div v-html="contentHtmlTHPT"></div>
      </div>
      <!-- CAO DANG -->
      <div v-show="showCaoDang" class="padding-top15 content">
        <div class="title-bai-viet" v-html="titleHtml"></div>
        <div v-html="contentHtml"></div>
      </div>
      <!-- dai hoc -->
      <div v-show="showDaiHoc" class="content">
        <div class="title-bai-viet" v-html="titleHtml"></div>
        <div v-html="contentHtml"></div>
      </div>
      <div v-show="showLienHe" class="content padding-top15">
        <div class="title-bai-viet" v-html="titleHtmlLienHe"></div>
        <div v-html="contentHtmlLienHe"></div>
      </div>
      <!--  educaticon--------------------------------------------------------------------------------------------- -->

      <div v-show="showYTe" class="content padding-top15">
        <div class="title-bai-viet" v-html="titleHtml"></div>
        <div v-html="contentHtml"></div>
      </div>
      <!-- tieu hoc-->
      <div v-show="showBenhVienTinh" class="content padding-top15">
        <div class="title-bai-viet" v-html="titleHtml"></div>
        <div v-html="contentHtml"></div>
      </div>
      <!-- BenhVienHuyen -->
      <div v-show="showBenhVienHuyen" class="content padding-top15">
        <div class="title-bai-viet" v-html="titleHtml"></div>
        <div v-html="contentHtml"></div>
      </div>
      <!-- CoSoYTeXa -->
      <div v-show="showCoSoYTeXa" class="content padding-top15">
        <div class="title-bai-viet" v-html="titleHtml"></div>
        <div v-html="contentHtml"></div>
      </div>
      <!-- context -->
      <div v-show="showLienCap" class="content padding-top15">
        <div class="title-bai-viet" v-html="titleHtmlLienCap"></div>
        <div v-html="contentHtmlLienCap"></div>
      </div>
      <!--------------------------- AI -------------------->
      <div v-show="showDeepSeek" class="content padding-top15">
        <div class="title-bai-viet" v-html="titleHtmlDeepSeek"></div>
        <div v-html="contentHtmlDeepSeek"></div>
      </div>
      <div v-show="showChatGPT" class="content padding-top15">
        <div class="title-bai-viet" v-html="titleHtmlChatGPT"></div>
        <div v-html="contentHtmlChatGPT"></div>
      </div>
      <div v-show="showGemini" class="content padding-top15">
        <div class="title-bai-viet" v-html="titleHtmlGemini"></div>
        <div v-html="contentHtmlGemini"></div>
      </div>
      <div v-show="showCopilot" class="content padding-top15">
        <div class="title-bai-viet" v-html="titleHtmlCopilot"></div>
        <div v-html="contentHtmlCopilot"></div>
      </div>
      <div v-show="showGrok" class="content padding-top15">
        <div class="title-bai-viet" v-html="titleHtmlGrok"></div>
        <div v-html="contentHtmlGrok"></div>
      </div>
      <div v-show="showMeta" class="content padding-top15">
        <div class="title-bai-viet" v-html="titleHtmlMeta"></div>
        <div v-html="contentHtmlMeta"></div>
      </div>
      <div v-show="showManus" class="content padding-top15">
        <div class="title-bai-viet" v-html="titleHtmlManus"></div>
        <div v-html="contentHtmlManus"></div>
      </div>
      <div v-show="showPerplexity" class="content padding-top15">
        <div class="title-bai-viet" v-html="titleHtmlPerplexity"></div>
        <div v-html="contentHtmlPerplexity"></div>
      </div>
    </main>
  </div>
  <FooterView></FooterView>
</template>
    <script>
import FooterView from "@/views/Vimass/Footer/FooterView.vue";
import { languages } from "@/components/languages";
import { apiChiTietBaiViet } from "@/components/listService";
import axios from "axios";
import UngDungAI from "../MainBaiVietCon/ThietBiVaDichVu/HeThong/UngDungAI.vue";
export default {
  components: {
    FooterView,UngDungAI
  },
  data() {
    return {
      activeTab: "AITaiLopHoc",
      isMenuVisible: false,
      currentLanguage: languages.vi,
      lang: "",
      base64: "",
      base64Title: "",
      //show
      showAITaiLopHoc: true,
      showThiDiem: false,
      showTaiTro: false,
      showTieuHoc: false,
      showTHCS: false,
      showTHPT: false,
      showCaoDang: false,
      showDaiHoc: false,
      showYTe: false,
      showBenhVienTinh: false,
      showBenhVienHuyen: false,
      showCoSoYTeXa: false,
      showLienHe: false,
      showLienCap: false,
      showDeepSeek: false,
      showChatGPT: false,
      showGemini: false,
      showCopilot: false,
      showGrok: false,
      showMeta: false,
      showManus: false,
      showPerplexity: false,
      showUngDungAI: false,

      titleHtmlAITaiLopHoc: "",
      contentHtmlAITaiLopHoc: "",
      titleHtml: "",
      contentHtml: "",
      titleHtmlTruongTaiTro: "",
      contentHtmlTruongTaiTro: "",
      titleHtmlTH: "",
      contentHtmlTH: "",
      titleHtmlTHCS: "",
      contentHtmlTHCS: "",
      titleHtmlTHPT: "",
      contentHtmlTHPT: "",
      titleHtmlLienHe: "",
      contentHtmlLienHe: "",
      titleHtmlLienCap: "",
      contentHtmlLienCap: "",

      titleHtmlPerplexity:"",
      titleHtmlDeepSeek:"",
      titleHtmlChatGPT:"",
      titleHtmlGemini:"",
      titleHtmlCopilot:"",
      titleHtmlGrok:"",
      titleHtmlMeta:"",
      titleHtmlManus:"",

      contentHtmlPerplexity:"",
      contentHtmlDeepSeek:"",
      contentHtmlChatGPT:"",
      contentHtmlCopilot:"",
      contentHtmlGemini:"",
      contentHtmlGrok:"",
      contentHtmlMeta:"",
      contentHtmlManus:"",
      hasMounted: false,
    };
  },

  mounted() {
    if (window.innerWidth <= 768) {
      this.isMenuVisible = false;
    } else {
      this.isMenuVisible = true;
    }
    if (!this.hasMounted) {
      this.hasMounted = true; // Set the flag to true to indicate that mounted has been executed
      this.lang = localStorage.getItem("languages");
      if (this.lang == "vi") {
        document.title = "Vimass.vn - Giáo dục";
      } else if (this.lang == "en") {
        document.title = "Vimass.vn - Education";
      } else if (this.lang == "cn") {
        document.title = "Vimass.vn - 教育";
      } else if (this.lang == "kr") {
        document.title = "Vimass.vn - 교육과";
      }
      // window.location.href = this.lang + "/giaoduc#GiaoDuc/";
      document.getElementById("urlHome").setAttribute("href", this.lang);
      document
        .getElementById("urlAITaiLopHoc")
        .setAttribute("href", this.lang + "/giaoduc#phocaptrituenhantao/");
      document
        .getElementById("urlThiDiem")
        .setAttribute("href", this.lang + "/giaoduc#chuongtrinhtaitro/");
      document
        .getElementById("urlBai2")
        .setAttribute("href", this.lang + "/giaoduc#trunghoccoso/");
      document
        .getElementById("urlBai3")
        .setAttribute("href", this.lang + "/giaoduc#trunghocphothong/");
       document.getElementById("urlTaiTro").setAttribute("href", this.lang + "/giaoduc#thoathuantaitro/");
      document
        .getElementById("urlBai1")
        .setAttribute("href", this.lang + "/giaoduc#tieuhoc/");
      document
        .getElementById("urlLienCap")
        .setAttribute("href", this.lang + "/giaoduc#liencap/");
      document
        .getElementById("urlLienHe")
        .setAttribute("href", this.lang + "/giaoduc#lienhe/");
      //   document
      //   .getElementById("urlDeepSeek")
      //   .setAttribute("href", this.lang + "/giaoduc#deepseek/");
      //   document
      //   .getElementById("urlChatGPT")
      //   .setAttribute("href", this.lang + "/giaoduc#chatgpt/");
      //   document
      //   .getElementById("urlGemini")
      //   .setAttribute("href", this.lang + "/giaoduc#gemini/");
      //   document
      //   .getElementById("urlCopilot")
      //   .setAttribute("href", this.lang + "/giaoduc#copilot/");
      //   documentddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd
      //   .getElementById("urlGrok")
      //   .setAttribute("href", this.lang + "/giaoduc#grok/");
       
      //   document
      //   .getElementById("urlMeta")
      //   .setAttribute("href", this.lang + "/giaoduc#metaai/");
      //   document
      //   .getElementById("urlManus")
      //   .setAttribute("href", this.lang + "/giaoduc#manus/");
      // document
      //   .getElementById("urlTaiTro")
      //   .setAttribute("href", this.lang + "/giaoduc#dangkytaitro/");
        document
        .getElementById("urlPerplexity")
        .setAttribute("href", this.lang + "/giaoduc#thuvientruong/");
        document
        .getElementById("urlUngDungAI")
        .setAttribute("href", this.lang + "/giaoduc#ungdungaiphobien/");  
      const hash = window.location.hash;
      if (hash && hash.includes("#phocaptrituenhantao")) {
        this.changeTab("AITaiLopHoc");
        // } else if (hash && hash.includes("#truongduoctaitro")) {
        //   this.changeTab("taitro");





















      } else if (hash && hash.includes("#thoathuantaitro")) {
         this.changeTab("taitro");
      } else if (hash && hash.includes("#chuongtrinhtaitro")) {
        this.changeTab("thidiem");
      } else if (hash && hash.includes("#trunghoccoso")) {
        this.changeTab("thcs");
      } else if (hash && hash.includes("#trunghocphothong")) {
        this.changeTab("thpt");
      } else if (hash && hash.includes("#dangkytaitro")) {
        this.changeTab("taitro");
      } else if (hash && hash.includes("#tieuhoc")) {
        this.changeTab("th");
      } else if (hash && hash.includes("#lienhe")) {
        this.changeTab("lienhe");
      } else if (hash && hash.includes("#liencap")) {
        this.changeTab("liencap");
      } else if (hash && hash.includes("#deepseek")) {
        this.changeTab("DeepSeek");
      }else if (hash && hash.includes("#chatgpt")) {
        this.changeTab("ChatGPT");
      }else if (hash && hash.includes("#gemini")) {
        this.changeTab("Gemini");
      }else if (hash && hash.includes("#copilot")) {
        this.changeTab("Copilot");
      }else if (hash && hash.includes("#grok")) {
        this.changeTab("Grok");
      }else if (hash && hash.includes("#metaai")) {
        this.changeTab("Meta");
      }else if (hash && hash.includes("#manus")) {
        this.changeTab("Manus");
      }else if (hash && hash.includes("#thuvientruong")) {
        this.changeTab("Perplexity");
      }else if (hash && hash.includes("#ungdungaiphobien")) {
        this.changeTab("ungDungAI");
      }else {
        this.changeTab("thidiem");
      }
    }

    this.performPostRequest("1741150321722AwaTo", "AITaiLopHoc", this.lang);
  },
  created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
  methods: {
    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
      // window.location.reload();
      window.location.href = "/" + lang + "/giaoduc#phocaptrituenhantao/";
    },
    toggleMenu() {
      this.isMenuVisible = true;
    },
    changeTab(tab) {
      if (window.innerWidth <= 768) {
        this.isMenuVisible = false;
      }
      this.lang = localStorage.getItem("languages");

      const tabStates = {
        AITaiLopHoc: false,
        thidiem: false,
        th: false,
        thcs: false,
        thpt: false,
        caodang: false,
        daihoc: false,
        Yte: false,
        BenhVienTinh: false,
        BenhVienHuyen: false,
        CoSoYTeXa: false,
        lienhe: false,
        liencap: false,
        DeepSeek: false,ChatGPT: false,Gemini: false,Copilot: false,ungDungAI:false,
        Perplexity:false
      };

      this.performPostRequest(this.getPostId(tab), tab, this.lang);

      tabStates[tab] = true;
      this.activeTab = tab;
      this.showAITaiLopHoc = tabStates.AITaiLopHoc;
      this.showThiDiem = tabStates.thidiem;
      this.showTaiTro = tabStates.taitro;
      this.showTieuHoc = tabStates.th;
      this.showTHCS = tabStates.thcs;
      this.showTHPT = tabStates.thpt;
      this.showCaoDang = tabStates.caodang;
      this.showDaiHoc = tabStates.daihoc;
      this.showUngDungAI = tabStates.ungDungAI;
      this.showYTe = tabStates.Yte;
      this.showBenhVienTinh = tabStates.BenhVienTinh;
      this.showBenhVienHuyen = tabStates.BenhVienHuyen;
      this.showCoSoYTeXa = tabStates.CoSoYTeXa;
      this.showLienHe = tabStates.lienhe;
      this.showLienCap = tabStates.liencap;
      this.showDeepSeek = tabStates.DeepSeek;
      this.showChatGPT = tabStates.ChatGPT;
      this.showGemini = tabStates.Gemini;
      this.showCopilot = tabStates.Copilot;
      this.showGrok = tabStates.Grok;
      this.showMeta = tabStates.Meta;
      this.showManus = tabStates.Manus;
      this.showPerplexity = tabStates.Perplexity;
    },
    async performPostRequest(id, section) {
      this.lang = localStorage.getItem("languages");

      try {
        // Thực hiện POST request
        const response = await axios.post(apiChiTietBaiViet, {
          id: id,
          langId: "0",
        });
        if (this.lang === "vi") {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        } else if (this.lang === "en") {
          this.base64 = response.data.result.content_en;
          this.base64Title = response.data.result.title_en;
        } else if (this.lang === "cn") {
          this.base64 = response.data.result.content_cn;
          this.base64Title = response.data.result.title_cn;
        } else if (this.lang === "kr") {
          this.base64 = response.data.result.content_kr;
          this.base64Title = response.data.result.title_kr;
        } else {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        }

        var title = atob(this.base64Title);
        var decodedString = atob(this.base64);

        // Store content separately for each section
        if (section === "AITaiLopHoc") {
          this.titleHtmlAITaiLopHoc = decodeURIComponent(escape(title));
          this.contentHtmlAITaiLopHoc = decodeURIComponent(
            escape(decodedString)
          );
        } else if (section === "thidiem") {
          this.titleHtml = decodeURIComponent(escape(title));
          this.contentHtml = decodeURIComponent(escape(decodedString));
        } else if (section === "taitro") {
          this.titleHtmlTruongTaiTro = decodeURIComponent(escape(title));
          this.contentHtmlTruongTaiTro = decodeURIComponent(
            escape(decodedString)
          );
        } else if (section === "th") {
          this.titleHtmlTH = decodeURIComponent(escape(title));
          this.contentHtmlTH = decodeURIComponent(escape(decodedString));
        } else if (section === "thcs") {
          this.titleHtmlTHCS = decodeURIComponent(escape(title));
          this.contentHtmlTHCS = decodeURIComponent(escape(decodedString));
        } else if (section === "thpt") {
          this.titleHtmlTHPT = decodeURIComponent(escape(title));
          this.contentHtmlTHPT = decodeURIComponent(escape(decodedString));
        } else if (section === "caodang") {
          this.titleHtml = decodeURIComponent(escape(title));
          this.contentHtml = decodeURIComponent(escape(decodedString));
        } else if (section === "daihoc") {
          this.titleHtml = decodeURIComponent(escape(title));
          this.contentHtml = decodeURIComponent(escape(decodedString));
        }
        if (section === "Yte") {
          this.titleHtml = decodeURIComponent(escape(title));
          this.contentHtml = decodeURIComponent(escape(decodedString));
        } else if (section === "BenhVienTinh") {
          this.titleHtml = decodeURIComponent(escape(title));
          this.contentHtml = decodeURIComponent(escape(decodedString));
        } else if (section === "BenhVienHuyen") {
          this.titleHtml = decodeURIComponent(escape(title));
          this.contentHtml = decodeURIComponent(escape(decodedString));
        } else if (section === "CoSoYTeXa") {
          this.titleHtml = decodeURIComponent(escape(title));
          this.contentHtml = decodeURIComponent(escape(decodedString));
        } else if (section === "liencap") {
          this.titleHtmlLienCap = decodeURIComponent(escape(title));
          this.contentHtmlLienCap = decodeURIComponent(escape(decodedString));
        } else if (section === "lienhe") {
          this.titleHtmlLienHe = decodeURIComponent(escape(title));
          this.contentHtmlLienHe = decodeURIComponent(escape(decodedString));
        } else if (section === "DeepSeek") {
          this.titleHtmlDeepSeek = decodeURIComponent(escape(title));
          this.contentHtmlDeepSeek = decodeURIComponent(escape(decodedString));
        } else if (section === "ChatGPT") {
          this.titleHtmlChatGPT = decodeURIComponent(escape(title));
          this.contentHtmlChatGPT= decodeURIComponent(escape(decodedString));
        } else if (section === "Gemini") {
          this.titleHtmlGemini = decodeURIComponent(escape(title));
          this.contentHtmlGemini = decodeURIComponent(escape(decodedString));
        } else if (section === "Copilot") {
          this.titleHtmlCopilot = decodeURIComponent(escape(title));
          this.contentHtmlCopilot = decodeURIComponent(escape(decodedString));
        }else if (section === "Grok") {
          this.titleHtmlGrok = decodeURIComponent(escape(title));
          this.contentHtmlGrok= decodeURIComponent(escape(decodedString));
        } else if (section === "Meta") {
          this.titleHtmlMeta = decodeURIComponent(escape(title));
          this.contentHtmlMeta = decodeURIComponent(escape(decodedString));
        } else if (section === "Manus") {
          this.titleHtmlManus = decodeURIComponent(escape(title));
          this.contentHtmlManus = decodeURIComponent(escape(decodedString));
        }else if (section === "Perplexity") {
          this.titleHtmlPerplexity = decodeURIComponent(escape(title));
          this.contentHtmlPerplexity = decodeURIComponent(escape(decodedString));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    getPostId(tab) {
      // Sử dụng một đối tượng để ánh xạ tab với id của bài viết tương ứng
      const postIdMap = {
        //  GiaoDuc: "1694406782839x1e10",
        //  thidiem:"1697101199141EwMrG",
        AITaiLopHoc: "1741150321722AwaTo", // new
        thidiem: "16914910788912yc0k", // new
        //  taitro:"1688091199705sulw5", // tai trợ id cũ
        taitro: "1740132509243YrXUM", //id trường tài trợ 1739434850034SDLRk
        th: "1693973335517jxry9", //17387455073451BmsV tiểu học cũ
        thcs: "1739438388417tCqND", //17387455626461UnNp thcs
        thpt: "1739438558334XX2rZ", // 1738745617316CfQ3zthpt cũ
        caodang: "16945770557827dnkj",
        daihoc: "1693973557639bchbw",
        Yte: "17295687643578FvBH",
        BenhVienTinh: "16944049354721oe4u",
        BenhVienHuyen: "1694405009668apaxz",
        CoSoYTeXa: "16914910788912yc0k",
        lienhe: "1690272053288yg3c7",
        liencap: "1739767772207RaIM4",
        DeepSeek: "1739938050389coAMh",
        ChatGPT: "17399527093351ejje",
        Gemini: "1739952756556eTbQQ",
        Copilot: "1739962827008hEz13",
        Grok: "1741754513134ENfrm",
        Meta: "1741754576070zd1Fd",
        Manus: "1741754674991tS4fV",
        Perplexity: "1742974393062SuECz",
      };

      return postIdMap[tab];
    },
  },
};
</script>

<style scoped>
ul li a {
  color: white !important;
}

.content {
  min-height: 500px;
  /* background-image: none !important */
}

.submenu {
  display: none;
  position: absolute;
  background-color: #4da9e0;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  list-style: none;
  padding: 0;
  margin: 0;
}

li:hover .submenu {
  display: block;
}

.submenu li {
  padding: 1px 1px;
  text-decoration: none;
  display: block;
}

.submenu li a {
  color: black;
  display: block;
  text-decoration: none;
  font-size: 14px;
  padding: 6px 2px !important;
}

.submenu li:hover {
  background-color: #ddd;
}

li {
  position: relative;
}
</style>