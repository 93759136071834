import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/Vimass/Home/HomeView.vue";
import MainGiaiPhapChuyenDoiSo from "../views/8_View_Layer/MainBaiVietTong/MainGiaiPhapChuyenDoiSo.vue";
import ChiTietBaiVietVue from "@/views/Vimass/Home/ChiTietBaiViet.vue";
import MainChuongTrinhTaiTroVue from "@/views/8_View_Layer/MainBaiVietTong/MainChuongTrinhTaiTro.vue";
//  import MainGiaoDuc from "@/views/8_View_Layer/MainBaiVietTong/MainGiaoDuc.vue";
import MainYTe from "@/views/8_View_Layer/MainBaiVietTong/MainYTe.vue";
import MainGiaoThongVaDichVuCong from "@/views/8_View_Layer/MainBaiVietTong/MainGiaoThongVaDichVuCong.vue";
import MainDTTvaDinhDanh from "@/views/8_View_Layer/MainBaiVietTong/MainDTTvaDinhDanh.vue";
import MainDinhDanhXacThuc from "@/views/8_View_Layer/MainBaiVietTong/MainDinhDanhXacThuc.vue";

import MainThietBiVaDichVu from "@/views/8_View_Layer/MainBaiVietTong/MainThietBiVaDichVu.vue";
import MainCongTy from "@/views/8_View_Layer/MainBaiVietTong/MainCongTy.vue";
import VongQuayVueVue from "@/views/8_View_Layer/MainBaiVietCon/VongQuay/VongQuayVue.vue";
import DownloadVimass from "@/views/Download/DownloadVimass.vue";
import DownloadVcard from "@/views/Download/DownloadVcard.vue";
import DownloadVpass from "@/views/Download/Vpass/DownloadVpass.vue";
import DownloadVpass1 from "@/views/Download/DownloadVpass.vue";
import test from "@/views/test.vue";
import VposLogin from "@/views/VPOS/Accounts/VposLogin.vue";
import VposRegister from "@/views/VPOS/Accounts/VposRegister.vue";
import VposQuenMatKhau from "@/views/VPOS/Accounts/VposQuenMatKhau.vue";


import VposHome from "@/views/VPOS/Home/VposHome.vue";
import ChinhSachVpos from "@/views/8_View_Layer/ChinhSachVpos.vue";
import DownloadVpos from "@/views/Download/DownloadVpos.vue";
import VposChiTietCuaHang from "@/views/VPOS/ChiTietCuaHang/VposChiTietCuaHang.vue";
import vpassThongKe from "@/views/VPass/vpassThongKe.vue";
import wedding from "@/views/wedding/danhBacWedding.vue";
import CreateStore from "@/views/VPOS/DashHome/CreateStore.vue";
import loginByEmail from "@/views/VPOS/LoginEmail/registerByEmail.vue";

import CategoryComponent from "@/views/VPOS/DashHome/CategoryComponent.vue";
import ProductComponent from "@/views/VPOS/DashHome/ProductComponent.vue"
import UpdateStore from "@/views/VPOS/DashHome/UpdateStore.vue";
import SaoKeComponent from "@/views/VPOS/DashHome/SaoKeComponent.vue"
import MainGiaoDucVaYTe from "@/views/8_View_Layer/MainBaiVietTong/MainGiaoDucVaYTe.vue";
import DayAnhLenServer from "@/views/Vimass/Home/DayAnhLenServer.vue";
import MainDVThuChiHo from "@/views/8_View_Layer/MainBaiVietTong/MainDVThuChiHo.vue";
import MainQRAmThanh from "@/views/8_View_Layer/MainBaiVietTong/MainQRAmThanh.vue";
import dsAiVueVue from "@/views/DeepSeekAI/dsAiVue.vue";
import ChiTietBaiVietTheoTenVue from "@/views/Vimass/Home/ChiTietBaiVietTheoTen.vue";
import ChiTietBaiVietGiaoDucVue from "@/views/Vimass/Home/ChiTietBaiVietGiaoDuc.vue";
import DownloadPerplexitiVue from "@/views/Download/DownloadPerplexiti.vue";
import DownloadGeminiVue from "@/views/Download/DownloadGemini.vue";
import DownloadChatGPTVue from "@/views/Download/DownloadChatGPT.vue";
import DownloadDeepSeekVue from "@/views/Download/DownloadDeepSeek.vue";
import DownloadGrokVue from "@/views/Download/DownloadGrok.vue";
import DownloadCopilotVue from "@/views/Download/DownloadCopilot.vue";
import DownloadSpeetTestVue from "@/views/Download/DownloadSpeetTest.vue";
const routes = [
  {
    path: "/",
    redirect: "/vi",
  },

  {
    path: "/:lang/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/test",
    name: "test",
    component: test,
  },

  {
    path: "/policy-vpos",
    name: "PolicyVpos",
    component: ChinhSachVpos,
  }, 

  {
    path: "/download/vpass",
    name: "RedirectVpass",
    component: DownloadVpass,
  },
  {
    path: "/download/vpos",
    name: "RedirectVpos",
    component: DownloadVpos,
  },
  {
    path: "/download/vcard",
    name: "downloadvcard",
    component: DownloadVcard,
  },
  {
    path: "/download/vivimass",
    name: "downloadvimass",
    component: DownloadVimass,
  },
  
  {
    path: "/downloadvpass",
    name: "RedirectVpass1",
    component: DownloadVpass1,
  },

  {
    path: "/download/perplexity",
    name: "perplexity",
    component: DownloadPerplexitiVue,
  },
  {
    path: "/download/gemini",
    name: "gemini",
    component: DownloadGeminiVue,
  },
  {
    path: "/download/chatgpt",
    name: "chatgpt",
    component: DownloadChatGPTVue,
  },
  {
    path: "/download/deepseek",
    name: "deepseek",
    component: DownloadDeepSeekVue,
  },
  {
    path: "/download/grok",
    name: "grok",
    component: DownloadGrokVue,
  },
  {
    path: "/download/copilot",
    name: "copilot",
    component: DownloadCopilotVue,
  },
  {
    path: "/download/speedtest",
    name: "speedtest",
    component: DownloadSpeetTestVue,
  },
  // {
  //   path: "/download/deepseek",
  //   name: "deepseek",
  //   component: DownloadDeepSeekVue,
  // },
  {
    path: "/:lang/giaiphapchuyendoiso",
    name: "giaiphapchuyendoiso",
    component: MainGiaiPhapChuyenDoiSo,
  },
  {
    path: "/:lang/giaiphapchuyendoisodonvi",
    name: "dinhdanhxacthuc",
    component: MainDinhDanhXacThuc,
  },
  {
    path: "/:lang/qramthanh",
    name: "qramthanh",
    component: MainQRAmThanh,
  },
  {
    path: "/:lang/chuongtrinhthidiemvataitroaaaaa",
    name: "chuongtrinhthidiemvataitro",
    component: MainChuongTrinhTaiTroVue,
  },
  // {
  //   path: "/:lang/giaoduc1",
  //   name: "giaoduc",
  //   component: MainGiaoDuc,
  // },
  {
    path: "/:lang/giaoduc",
    name: "giaoducvayte",
    component: MainGiaoDucVaYTe,
  },
  {
    path: "/:lang/y-te",
    name: "yte",
    component: MainYTe,
  },
  {
    path: "/:lang/ytegiaothongvadichvucong",
    name: "giaothongvadichvucong",
    component: MainGiaoThongVaDichVuCong,
  },
  {
    path: "/:lang/dichvuthuchiho",
    name: "dichvuthuchiho",
    component: MainDVThuChiHo,
  },
  {
    path: "/:lang/thesinhtrachoc",
    name: "thesinhtrachoc",
    component: MainDTTvaDinhDanh,
  },
 
  {
    path: "/:lang/thietbivadichvu",
    name: "thietbivadichvu",
    component: MainThietBiVaDichVu,
  },
  {
    path: "/:lang/congty",
    name: "congty",
    component: MainCongTy,
  },
  {
    path: "/:lang/chi-tiet.html/:id",
    name: "chitietbaiviet",
    component: ChiTietBaiVietVue,
  },
  {
    path: "/:lang/bai-viet.html/:idTitleVi",
    name: "chitietbaiviettheoten",
    component: ChiTietBaiVietTheoTenVue,
    props: true
  },

  {
    path: "/:lang/giao-duc/:idTitleVi",
    name: "ChiTietBaiVietGiaoDucVue",
    component: ChiTietBaiVietGiaoDucVue,
    props: true
  },
  {
    path: "/vongquaynammoi",
    name: "vongquaynammoi",
    component: VongQuayVueVue,
  },

  // vpossss

  {
    path: "/:lang/vpass",
    name: "vpass",
    component: vpassThongKe,
  },
  {
    path: "/:lang/pos",
    name: "dangnhap",
    component: VposHome,
  },
  {
    path: "/vpos/dangnhap",
    name: "user",
    component: VposLogin,
  },
  {
    path: "/vm/dichvudayanhdanhchocbnv",
    name: "DayAnhLenServer",
    component: DayAnhLenServer,
  },
  
  {
    path: "/vpos/dangky",
    name: "dangky",
    component: VposRegister,
  },
  {
    path: "/vpos/quenmatkhau",
    name: "quenmatkhau",
    component: VposQuenMatKhau,
  },
  {
    path: "/vpos/trangchu",
    name: "trangchu",
    component: VposHome,
  },
  {
    path: "/vpos/taocuahang",
    name: "taocuahang",
    component: CreateStore,
  },
  {
    path: "/vpos/taothaydoi",
    name: "suacuahang",
    component: UpdateStore,
  },
  {
    path: "/ai/dseek",
    name: "ai",
    component: dsAiVueVue,
  },
  {
    path: "/vpos/o",
    name: "chitietcuahang",
    component: VposChiTietCuaHang,
    props: (route) => ({
      idStore: route.query.idStore,
      idBanAn: route.query.idBanAn
    })
  },
  {
    path: "/wedding/dangdanhnguyenbac",
    name: "wedding",
    component: wedding,
  },
  {
    path: "/loginByEmail",
    name: "loginByEmail",
    component: loginByEmail,
  },
  // Category
  {
    path: "/vpos/Category",
    name: "CategoryComponent",
    component: CategoryComponent,
  },
  // Product
  {
    path: "/vpos/Product",
    name: "ProductComponent",
    component: ProductComponent,
  },
  //SaoKeComponent
  {
    path: "/vpos/Saoke",
    name: "SaoKeComponent",
    component: SaoKeComponent,
  },
  //Taocuahang

  {
    path: "/vpos/Saoke",
    name: "SaoKeComponent",
    component: SaoKeComponent,
  },
//   {
   
//     path: "/download",
//     redirect: "/vi/thesinhtrachoc",
// },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const lang = to.params.lang || "vi";
  // const supportedLangs = ["vi", "en", "cn", "kr", "ja", "vpos"];
  const supportedLangs = ["vi", "en", "vpos"];
  // Kiểm tra nếu đường dẫn kết thúc bằng dấu "/" và chỉ có ngôn ngữ
  if (supportedLangs.includes(lang) && to.path.endsWith("/") && to.path === `/${lang}/`) {
    return next(`/${lang}`); // Điều hướng đến đường dẫn không có dấu "/"
  }else
  if (to.path === `/download`) {
    return next(`/${lang}/thesinhtrachoc`); // Điều hướng đến đường dẫn không có dấu "/"
  }
  if (!supportedLangs.includes(lang)) {
    return next(`/vi${to.fullPath.substr(5)}`); // Chuyển hướng về '/vi' và giữ nguyên đường dẫn hiện tại (loại bỏ ngôn ngữ)
  }

  next();
});

export default router;
