<template>
     <p id="demo"></p>
</template>
<script>
export default {
   
  name: 'RedirectComponent',
  mounted() {
    
    var str = window.navigator.userAgent;
    var str1 = "https://chatgpt.com/"; // Default URL

    if (str.indexOf("Android") != -1) {
      str1 = "https://play.google.com/store/apps/details?id=com.openai.chatgpt&hl=vi";
      //https://play.google.com/store/apps/details?id=com.qr.vcard&pcampaignid=web_share
    } else if (str.indexOf("iPhone") != -1) {
      str1 = "https://apps.apple.com/us/app/chatgpt/id6448311069";
    }
    
    window.location.href = str1;
    console.log(str);
  }
}

</script>